import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/sonsberg.css';
import config from './config.js';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

const RegisterScreen = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [registerError, setRegisterError] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setValidPassword(e.target.value.length >= 5);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValidEmail(validateEmail(e.target.value));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.name === 'username') {
        document.getElementById('password').focus();
      } else if (e.target.name === 'password') {
        emailRef.current.focus();
      } else if (e.target.name === 'email') {
        handleRegister();
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRegister = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!validEmail || !validPassword) {
      return;
    }

    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/user/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
          email,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Registrierung erfolgreich
        setRegisterError(false);
        navigate('/registration-success'); // Umleitung zur Hauptseite
      } else {
        // Registrierung fehlgeschlagen
        console.error('Registrierung fehlgeschlagen:', data.message);
        setRegisterError(true);
      }
    } catch (error) {
      console.error('Fehler bei der Registrierung:', error);
      setRegisterError(true);
    }
  };

  return (
    <div className="login-container">
      <h2>Registrierung</h2>
      {registerError && <p className="error-message">Registrierung fehlgeschlagen</p>}
      <input
        type="text"
        placeholder="Username"
        name="username"
        value={username}
        onChange={handleUsernameChange}
        onKeyDown={handleKeyDown}
        ref={usernameRef}
        className={registerError ? 'input-error' : ''}
      />
      <input
        type="password"
        placeholder="Password"
        name="password"
        id="password"
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown}
        className={`${registerError ? 'input-error' : ''} ${validPassword ? '' : 'input-error'}`}
      />
      {!validPassword && (
        <p className="error-message">Das Passwort muss mindestens 5 Zeichen lang sein</p>
      )}
      <input
        type="text"
        placeholder="Email"
        name="email"
        id="email"
        value={email}
        onChange={handleEmailChange}
        onKeyDown={handleKeyDown}
        ref={emailRef}
        className={`${registerError ? 'input-error' : ''} ${validEmail ? '' : 'input-error'}`}
      />
      {!validEmail && <p className="error-message">Bitte geben Sie eine gültige E-Mail-Adresse ein</p>}
      <button
        className={`login-button ${!validEmail || !validPassword ? 'disabled' : ''}`}
        onClick={handleRegister}
        disabled={!validEmail || !validPassword}
      >
        Registrieren
      </button>
      <p className="register-link">
        Bereits einen Account? <Link to="/login">Jetzt einloggen</Link>
      </p>
    </div>
  );
};

export default RegisterScreen;
