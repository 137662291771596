import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import config from './config.js';
import './css/sonsberg.css';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TextField, Button } from '@mui/material';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email) {
      try {
        const response = await fetch(`${serverUrl}:${serverPort}/be/user/forgotPassword`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
          }),
        });
        const data = await response.json();
        if (response.ok) {
          setMessage('Eine E-Mail mit weiteren Anweisungen wurde an Ihre E-Mail-Adresse gesendet.');
        } else {
          setMessage('Fehler beim Zurücksetzen des Passworts. Bitte überprüfen Sie Ihre E-Mail-Adresse.');
        }
      } catch (error) {
        console.error('Fehler beim Zurücksetzen des Passworts:', error);
        setMessage('Fehler beim Zurücksetzen des Passworts. Bitte überprüfen Sie Ihre E-Mail-Adresse.');
      }
    }
  };

  return (
    <div className="checkin-container">
      <h1>Passwort vergessen</h1>
      <form onSubmit={handleSubmit}>
        <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="checkin-table-cell">E-Mail Adresse</TableCell>
                <TableCell className="checkin-table-cell">
                  <TextField
                    type="email"
                    label="E-Mail-Adresse"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className="checkin-table-cell" style={{ textAlign: 'center' }}>
                  <Button type="submit" variant="contained" color="primary" className="details-button">
                    Passwort zurücksetzen
                  </Button>
                </TableCell>
              </TableRow>
              {message && (
                <TableRow>
                  <TableCell colSpan={2} className="checkin-table-cell" style={{textAlign:'center'}}>
                    <p>{message}</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
    </div>
  );
};

export default ForgotPasswordScreen;
