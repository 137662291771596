import React, { useEffect, useState } from 'react';
import NavBar from './NavBar';
import RoutesTable from './RoutesTable';
import RoutesTableRO from './RoutesTableRO';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';
import ProfileScreen from './ProfileScreen';
import RouteDetailsScreen from './RouteDetailsScreen';
import RegistrationSuccessScreen from './RegistrationSuccessScreen';
import ErrorPage from './ErrorPage';
import FilterScreen from './FilterScreen';
import StartScreen from './StartScreen';
import CheckinScreen from './CheckinScreen';
import AllCheckinsScreen from './AllCheckinsScreen';
import AdminScreen from './AdminScreen';
import AdminUserScreen from './AdminUserScreen';
import AdminNewsletterScreen from './AdminNewsletterScreen';
import CheckinReportScreen from './CheckinReportScreen';
import ChangePasswordScreen from './ChangePasswordScreen';
import ForgotPasswordScreen from './ForgotPasswordScreen';
import ResetPasswordScreen from './ResetPasswordScreen';
import AchievementsScreen from './AchievementsScreen';
import ProjectsScreen from './ProjectsScreen';
import SettingsScreen from './SettingsScreen';
import DeleteAccountScreen from './DeleteAccountScreen';
import UserNewsletterScreen from './UserNewsletterScreen';
import AdminRoutesScreen from './AdminRoutesScreen';
import AdminCreateRouteScreen from './AdminCreateRouteScreen';
import RouteDetailsAdminScreen from './RouteDetailsAdminScreen';

import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const Scale_UIAA = config.difficultyScaleUIAA;

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [difficultyScale, setDifficultyScale] = useState(localStorage.getItem('difficultyScale') || Scale_UIAA);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [filterOptions, setFilterOptions] = useState(() => {
    const storedFilterOptions = localStorage.getItem('filterOptions');
    return storedFilterOptions && storedFilterOptions !== 'undefined' ? JSON.parse(storedFilterOptions) : {
      difficultyFrom: '',
      difficultyTo: '',
      topropeClimbed: 'all',
      vorstiegClimbed: 'all',
    };
  });

  const handleDeleteFilter = () => {
    setFilterOptions(null);
    localStorage.removeItem('filterOptions');
  };

  const handleApplyFilter = async (newFilterOptions) => {
    setFilterOptions(newFilterOptions);
    const storedFilterOptions = JSON.stringify(newFilterOptions);
    localStorage.setItem('filterOptions', storedFilterOptions);
    const toStore = JSON.stringify({ filterOptions: newFilterOptions });
    try {
      await fetch(`${serverUrl}:${serverPort}/be/user/updateFilterOptions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: toStore,
      });
    } catch (error) {
      console.error('Fehler beim Update des Users:', error);
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, [token, isAdmin]);

  const checkLoginStatus = async () => {
    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/user/check-login`, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`
        }, 
        body: JSON.stringify({
          token: token,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setUserLoggedIn(data.token === token);
        setIsAdmin(data.isAdmin);
        setIsSupervisor(data.isSupervisor);
        setDifficultyScale(data.difficultyScale ? data.difficultyScale : Scale_UIAA);
      } else {
        handleLogout();
      }
    } catch (error) {
      handleLogout();
    }

    setLoading(false);
  };

  const handleSetToken = (newToken) => {
    setToken(newToken);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUserLoggedIn(false);
  };

  const handleUpdateDifficulty = (newDifficultyScale) => {
    setDifficultyScale(newDifficultyScale);
    localStorage.setItem('difficultyScale', newDifficultyScale)
  };

  return (
    <ErrorBoundary>
      <Router basename="/">
        <div className="App">
          <NavBar loggedIn={userLoggedIn} onLogout={handleLogout} filterOptions={filterOptions} onDeleteFilter={handleDeleteFilter} />
          <Routes>
            {loading ? (
              <Route path="/" element={<div>Lade Daten...</div>} />
            ) : (
              <>
                <Route path="/routes" element={userLoggedIn ? <RoutesTable filterOptions={filterOptions} difficultyScale={difficultyScale} /> : <RoutesTableRO />} />
                <Route path="/login" element={<LoginScreen setToken={handleSetToken} />} />
                <Route path="/" element={<StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route path="/registration-success" element={<RegistrationSuccessScreen />} />
                <Route path="/checkin" element={<CheckinScreen />} />
                <Route path="/allCheckins" element={<AllCheckinsScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/profile" element={userLoggedIn ? <ProfileScreen updateDifficulty={handleUpdateDifficulty} /> : <RoutesTableRO />} />
                <Route path="/achievements" element={userLoggedIn ? <AchievementsScreen difficultyScale={difficultyScale} /> : <RoutesTableRO />} />
                <Route path="/projects" element={userLoggedIn ? <ProjectsScreen difficultyScale={difficultyScale} /> : <RoutesTableRO />} />
                <Route path="/routeDetails/:routeId" element={userLoggedIn ? <RouteDetailsScreen difficultyScale={difficultyScale} /> : <RoutesTableRO />} />
                <Route path="/routeDetailsAdmin/:routeId" element={userLoggedIn && isAdmin ? <RouteDetailsAdminScreen /> : <RoutesTableRO />} />
                <Route path="/adminCreateRoute" element={userLoggedIn && isAdmin ? <AdminCreateRouteScreen /> : <RoutesTableRO />} />
                <Route path="/settings" element={userLoggedIn ? <SettingsScreen /> : <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/changePassword" element={userLoggedIn ? <ChangePasswordScreen /> : <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/forgotPassword" element={<ForgotPasswordScreen />} />
                <Route path="/userNewsletter" element={userLoggedIn ? <UserNewsletterScreen /> : <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/deleteAccount" element={userLoggedIn ? <DeleteAccountScreen onLogout={handleLogout} />: <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/resetPassword/:resetToken" element={<ResetPasswordScreen />} />
                <Route path="/logout" element={<Navigate to="/" />} />
                <Route path="/filter" element={userLoggedIn ? <FilterScreen filterOptions={filterOptions} onApplyFilter={handleApplyFilter} /> : <RoutesTableRO />} />
                <Route path="/admin" element={userLoggedIn && isAdmin ? <AdminScreen /> : <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/adminUser" element={userLoggedIn && isAdmin ? <AdminUserScreen /> : <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/adminRoutes" element={userLoggedIn && isAdmin ? <AdminRoutesScreen /> : <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/adminNewsletter" element={userLoggedIn && isAdmin ? <AdminNewsletterScreen /> : <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="/reports" element={userLoggedIn && isAdmin ? <CheckinReportScreen /> : <StartScreen userLoggedIn={userLoggedIn} isAdmin={isAdmin} isSupervisor={isSupervisor} />} />
                <Route path="*" element={<ErrorPage />} />
              </>
            )}
          </Routes>
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
