import React from 'react';
import { Link } from 'react-router-dom';
import './css/sonsberg.css';

const SettingsScreen = () => {

  return (
    <div className="start-container">
      <h1>Einstellungen</h1>
      <div className="tile-container">
        <Link to="/reports" className="tile">
          <h2>Password ändern</h2>
        </Link>

      </div>
    </div>
  );
};

export default SettingsScreen;
