import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import config from './config.js';
import './css/sonsberg.css';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;


function AdminRoutesScreen() {
  const [routes, setRoutes] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchRoutes();
  }, []);

  const fetchRoutes = async () => {
    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/routes/getall`);
      const data = await response.json();
      setRoutes(data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Routen:', error);
    }
  };

  const handleClick = routeId => {
    navigate(`/routeDetailsAdmin/${routeId}`);
  };

  const handleCreateRoute = () => {
    navigate(`/adminCreateRoute`);
  };

  return (
    <div className="admin-routes-container">
      <h1>Admin Routes Screen</h1>
      <p><Button type="submit" variant="contained" color="primary"  style={{ color: 'white' }}  className="details-button" onClick={handleCreateRoute}>
        <AddIcon />&nbsp;Neue Route hinzufügen
      </Button></p>
      <TableContainer component={Paper} className="admin-routes-table">
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#DBDCD7' }}>
              <TableCell className="table-cell-header">#</TableCell>
              <TableCell className="table-cell-name">Route Name</TableCell>
              <TableCell className="table-cell-header">Farbe</TableCell>
              <TableCell className="table-cell-header">UIAA-Grad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {routes.map((route) => (
              <TableRow key={route._id} onClick={() => handleClick(route._id)}>
                <TableCell className="table-cell">{route.routenumber}</TableCell>
                <TableCell className="table-cell-name">{route.routename}</TableCell>
                <TableCell className="table-cell">{route.color}</TableCell>
                    <TableCell className="table-cell">{route.difficulty}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AdminRoutesScreen;
