import React, { useEffect, useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, TextareaAutosize } from '@mui/material';
import { useParams } from 'react-router-dom';
import config from './config.js';
import './css/sonsberg.css';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const feedbackTimer = config.feedbackTimer;
const colorMapping = config.colorMapping;
const colorCircleStyle = config.colorCircleStyle;
const zebraCircleContainerStyle = config.zebraCircleContainerStyle;
const zebraCircleStyle1 = config.zebraCircleStyle1;
const zebraCircleStyle2 = config.zebraCircleStyle2;
const Scale_French = config.difficultyScaleFrench;
const Scale_YDS = config.difficultyScaleYDS;
const Scale_GB = config.difficultyScaleGB;
const difficultyMappingFrench = config.difficultyMappingFrench;
const difficultyMappingYDS = config.difficultyMappingYDS;
const difficultyMappingGB = config.difficultyMappingGB;

const RouteDetailsScreen = ({ difficultyScale }) => {
  const { routeId } = useParams();
  const [mappings, setMappings] = useState([]);
  const [route, setRoute] = useState(null);
  const [showCommentFeedback, setShowCommentFeedback] = useState(false);
  const [showProjectFeedback, setShowProjectFeedback] = useState(false);
  const [showTopropeFeedback, setShowTopropeFeedback] = useState(false);
  const [showVorstiegFeedback, setShowVorstiegFeedback] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetch(`${serverUrl}:${serverPort}/be/routes/getDetails/${routeId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
      body: JSON.stringify({
        routeId: routeId,
      }),
    })
      .then(response => response.json())
      .then(data => setRoute(data))
      .catch(error => console.error('Fehler beim Abrufen der Routen:', error));

    fetch(`${serverUrl}:${serverPort}/be/routes/getMappingForUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
      body: JSON.stringify({
        token: token,
      }),
    })
      .then(response => response.json())
      .then(data => {
        setMappings(data);
      })
      .catch(error => console.error('Fehler beim Abrufen der Routen:', error));

  }, [routeId, token, difficultyScale]);

  if (!route) {
    return <p>Lade Routen-Details...</p>;
  }

  const getMappingForRoute = (routeId) => {
    try {
      const mapping = mappings.find(mapping => mapping.route_id === routeId);
      return mapping ? mapping : { vorstieg: false, toprope: false, project: false, comment: '' };  
    } catch {
      //console.error('Fehler beim auslesen der Mappings');
    }
  };

  const handleCheckboxChange = (event, route, indicator) => {
    const isChecked = event.target.checked;
    const updatedMapping = { ...getMappingForRoute(route._id) };

    if(updatedMapping._id != null) {
      if (indicator === "toprope") {
        updatedMapping.toprope = isChecked;
      }
  
      if (indicator === "vorstieg") {
        updatedMapping.vorstieg = isChecked;
      }
  
      if (indicator === "project") {
        updatedMapping.project = isChecked;
      }
  
      fetch(`${serverUrl}:${serverPort}/be/routes/update-mapping/${updatedMapping._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify(updatedMapping),
      })
        .then(response => response.json())
        .then(data => {
          setMappings(prevMappings =>
            prevMappings.map(m => (m.route_id === route._id ? updatedMapping : m))
          );
  
          if (indicator === "toprope") {
            setShowTopropeFeedback(true);
            setTimeout(() => {setShowTopropeFeedback(false);}, feedbackTimer);
          }
          if (indicator === "vorstieg") {
            setShowVorstiegFeedback(true);
            setTimeout(() => {setShowVorstiegFeedback(false);}, feedbackTimer);
          }
          if (indicator === "project") {
            setShowProjectFeedback(true);
            setTimeout(() => {setShowProjectFeedback(false);}, feedbackTimer);
          }
  
        })
        .catch(error =>
          console.error('Fehler beim Aktualisieren des Datenbankeintrags:', error)
        );
    } else {
      // Mapping existiert noch nicht, lege einen neuen Eintrag an
      const newMapping = {
        route_id: route._id,
      };

      if (indicator === "toprope") {
        newMapping.toprope = isChecked;
      }
  
      if (indicator === "vorstieg") {
        newMapping.vorstieg = isChecked;
      }
  
      if (indicator === "project") {
        newMapping.project = isChecked;
      }

      fetch(`${serverUrl}:${serverPort}/be/routes/create-mapping`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify(newMapping),
      })
        .then(response => response.json())
        .then(data => {
          newMapping._id = data._id;
          setMappings([...mappings, newMapping]);
          if (indicator === "toprope") {
            setShowTopropeFeedback(true);
            setTimeout(() => {setShowTopropeFeedback(false);}, feedbackTimer);
          }
          if (indicator === "vorstieg") {
            setShowVorstiegFeedback(true);
            setTimeout(() => {setShowVorstiegFeedback(false);}, feedbackTimer);
          }
          if (indicator === "project") {
            setShowProjectFeedback(true);
            setTimeout(() => {setShowProjectFeedback(false);}, feedbackTimer);
          }
        })
        .catch(error => console.error('Fehler beim Erstellen des Datenbankeintrags:', error));
    }
  };

  const handleCommentChange = (event) => {
    const mapping = getMappingForRoute(route._id);
    const updatedMapping = {
      ...mapping,
      comment: event.target.value,
    };

    setMappings(mappings.map(m => (m.route_id === route._id ? updatedMapping : m)));
  };

  const handleSaveComment = () => {
    const mapping = getMappingForRoute(route._id);

    fetch(`${serverUrl}:${serverPort}/be/routes/update-mapping/${mapping._id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
      body: JSON.stringify(mapping),
    })
      .then(response => response.json())
      .then(data => {
//        console.log('Kommentar gespeichert:', data);
        // Zeige das Feedback an
        setShowCommentFeedback(true);
        setTimeout(() => {setShowCommentFeedback(false);}, feedbackTimer);
      })
      .catch(error => console.error('Fehler beim Speichern des Kommentars:', error));
  };
  
  const getDifficultyLabel = (routeDifficulty) => {
    if (difficultyScale === Scale_French) {
      return difficultyMappingFrench[routeDifficulty];
    }
    if (difficultyScale === Scale_YDS) {
      return difficultyMappingYDS[routeDifficulty];
    }
    if (difficultyScale === Scale_GB) {
      return difficultyMappingGB[routeDifficulty];
    }
    return routeDifficulty;
  };

  return (
    <div className="centered-container">
      <h1>Route Details</h1>
      <TableContainer component={Paper} className="details-table-container">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="details-cell">Name der Route:</TableCell>
              <TableCell className="details-cell">{route.routename}</TableCell>
            </TableRow>
            {route.description && (
              <TableRow>
                <TableCell className="details-cell">Beschreibung:</TableCell>
                <TableCell className="details-cell">
                  {route.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className="details-cell">{difficultyScale}-Grad:</TableCell>
              <TableCell className="details-cell">{getDifficultyLabel(route.difficulty)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Farbe der Griffe:</TableCell>
              <TableCell className="details-cell">{route.color === 'Zebra' ? (
                      <div style={zebraCircleContainerStyle}>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'black',
                            ...zebraCircleStyle1, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'white',
                            ...zebraCircleStyle2, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                      </div>
                    ) : route.color === 'Riss / Struktur' ? (
                      <div>Riss</div>
                    ) : (
                      // Standard-Kreis für andere Farben
                      <div
                        style={{
                          ...colorCircleStyle,
                          backgroundColor: colorMapping[route.color],
                        }}
                      ></div>
                    )}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Toprope geklettert <img src="/img/toprope_icon.png" width="20" alt="Toprope" /></TableCell>
              <TableCell className="details-cell">
                <Checkbox
                  checked={getMappingForRoute(route._id).toprope || false}
                  onChange={(event) => handleCheckboxChange(event, route, "toprope")}
                  style={showTopropeFeedback? { color: 'green' } : {}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Vorstieg geklettert <img src="/img/vorstieg_icon.png" width="20" alt="Vorstieg" /></TableCell>
              <TableCell className="details-cell">
                <Checkbox
                  checked={getMappingForRoute(route._id).vorstieg || false}
                  onChange={(event) => handleCheckboxChange(event, route, "vorstieg")}
                  style={showVorstiegFeedback? { color: 'green' } : {}}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Projekt:&nbsp;<BookmarkIcon style={{color: '#D10A11'}} /></TableCell>
              <TableCell className="details-cell">
                <Checkbox
                  checked={getMappingForRoute(route._id).project || false}
                  onChange={(event) => handleCheckboxChange(event, route, "project")}
                  style={showProjectFeedback? { color: 'green' } : {}}
                />
              </TableCell>
            </TableRow>
            {false && (
            <TableRow>
              <TableCell className="details-cell">Kommentar:</TableCell>
              <TableCell className="details-cell">
                <TextareaAutosize
                  value={getMappingForRoute(route._id).comment}
                  onChange={handleCommentChange}
                  minRows={3}
                  style={showCommentFeedback? {color: 'green', fontSize: '1rem', width: '80%'} : {fontSize: '1rem', width: '80%'}}
                />
                <Button className="details-button" variant="contained" onClick={handleSaveComment}>speichern</Button>
              </TableCell>
            </TableRow>)}
            {route.picture !== "" && (
            <TableRow>
              <TableCell colSpan={2} className="details-cell" style={{ textAlign:'center'}}><img src={`/img/routes/${route.picture}`} width="300" alt={`${route.routename}`}  /></TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RouteDetailsScreen;
