import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const difficultyOrder = config.difficultyOrder;
const colorMapping = config.colorMapping;
const colorCircleStyle = config.colorCircleStyle;
const zebraCircleContainerStyle = config.zebraCircleContainerStyle;
const zebraCircleStyle1 = config.zebraCircleStyle1;
const zebraCircleStyle2 = config.zebraCircleStyle2;

const RoutesTableRO = () => {
  const [routes, setRoutes] = useState([]);
  const [sortOrder, setSortOrder] = useState({ column: 'routenumber', direction: 'asc' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Rufe die Routen vom Server ab
    fetch(`${serverUrl}:${serverPort}/be/routes/getall`)
      .then(response => response.json())
      .then(data =>  {
        setRoutes(data)
        setLoading(false)
      })
      .catch(error => { 
        console.error('Fehler beim Abrufen der Routen:', error)
        setLoading(false);
      });
      handleSort('routenumber')
    }, [loading]);

  const handleSort = (column) => {
    const sortedRoutes = [...routes];

    if (column === 'routenumber') {
      sortedRoutes.sort((a, b) => {
        const valueA = Number(a[column])
        const valueB = Number(b[column])
        return valueA - valueB;

      });
    } else {
      sortedRoutes.sort((a, b) => {
        const valueA = String(a[column]).toLowerCase()
        const valueB = String(b[column]).toLowerCase()
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
      });
    }
  
    if (column === 'difficulty') {
      sortedRoutes.sort((a, b) => compareDifficulty(a[column], b[column]))
    }
  
    if (sortOrder[column] === 'desc') {
      sortedRoutes.reverse();
      setSortOrder({ ...sortOrder, [column]: 'asc' });
    } else {
      setSortOrder({ ...sortOrder, [column]: 'desc' });
    }
    setRoutes(sortedRoutes);

  };

  const compareDifficulty = (a, b) => {
    return difficultyOrder[a] - difficultyOrder[b];
  };


  return (
    <TableContainer component={Paper}>
      {loading ? (
      <p>Loading...</p> // Zeige eine Ladeanzeige oder einen Platzhalter an
    ) : (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#DBDCD7' }}>
            <TableCell onClick={() => handleSort('routename')} className="table-cell-header">
              Name der Route
            </TableCell>
            <TableCell onClick={() => handleSort('color')} className="table-cell-header">
              Farbe
            </TableCell>
            <TableCell onClick={() => handleSort('difficulty')} className="table-cell-header">
              UIAA-Grad
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {routes.map(route => (
            <TableRow key={route._id}>
              <TableCell className="table-cell-name">{route.routename}</TableCell>
              <TableCell className="table-cell">{route.color === 'Zebra' ? (
                      <div style={zebraCircleContainerStyle}>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'black',
                            ...zebraCircleStyle1, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'white',
                            ...zebraCircleStyle2, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                      </div>
                    ) : route.color === 'Riss / Struktur' ? (
                      <div>Riss</div>
                    ) : (
                      // Standard-Kreis für andere Farben
                      <div
                        style={{
                          ...colorCircleStyle,
                          backgroundColor: colorMapping[route.color],
                        }}
                      ></div>
                    )}</TableCell>
              <TableCell className="table-cell">{route.difficulty}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
       )}
    </TableContainer>
  );
}

export default RoutesTableRO;
