import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import TaskAlt from '@mui/icons-material/TaskAlt';
import config from './config.js';
import './css/sonsberg.css';

const Checkin = () => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [age, setAge] = useState(1);
  const [residence, setResidence] = useState('');
  const [membership, setMembership] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [checkinStatus, setCheckinStatus] = useState(null);
  const [formErrors, setFormErrors] = useState({
    acceptedTerms: false,
    firstName: false,
    lastName: false,
    age: false,
    residence: false,
    membership: false,
  });
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const serverUrl = config.serverUrl;
  const serverPort = config.serverPort;
  const newsletterOn = config.newsletterOn;
  
  useEffect(() => {
    if (token) {
      getCheckinData();
    }
  }, []);

  const handleTermsCheckboxChange = (event) => {
    setAcceptedTerms(event.target.checked);
    setFormErrors({ ...formErrors, acceptedTerms: false });
  };

  const handleNewsletterCheckboxChange = (event) => {
    setNewsletter(event.target.checked);

  };

  const handleNewsletterEmailChange = (event) => {
    setNewsletterEmail(event.target.value);

  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setFormErrors({ ...formErrors, firstName: false });
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    setFormErrors({ ...formErrors, lastName: false });
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
    setFormErrors({ ...formErrors, age: false });
  };

  const handleResidenceChange = (event) => {
    setResidence(event.target.value);
    setFormErrors({ ...formErrors, residence: false });
  };

  const handleMembershipChange = (event) => {
    setMembership(event.target.value === "Ja" ? true : false);
    setFormErrors({ ...formErrors, membership: false });
  };

  const getCheckinData = () => {
    fetch(`${serverUrl}:${serverPort}/be/user/getCheckinData`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.checkinData) {
          const checkinData = data.checkinData;
          setAcceptedTerms(checkinData.acceptedTerms);
          setFirstName(checkinData.firstName);
          setLastName(checkinData.lastName);
          setAge(checkinData.age);
          setResidence(checkinData.residence);
          setMembership(checkinData.membership);
          setNewsletter(checkinData.newsletter);
          setNewsletterEmail(checkinData.newsletterEmail);
          }
      },
        )
      .catch(error => {
        console.error('Fehler beim Einchecken:', error);
      });
  }

  const handleCheckin = (event) => {
    event.preventDefault();

      if (!acceptedTerms || !firstName || !lastName || !age || !residence) {
        setFormErrors({
          ...formErrors, 
            acceptedTerms: !acceptedTerms,
            firstName: !firstName,
            lastName: !lastName,
            age: !age,
            residence: !residence,
            membership: membership === undefined || membership === '' ? true : false,
          });
          setCheckinStatus('error');
          return;
      }
      
    const checkinData = {
        token: token,
        acceptedTerms,
        firstName,
        lastName,
        age,
        residence,
        membership,
        newsletter,
        newsletterEmail,
    };

    fetch(`${serverUrl}:${serverPort}/be/user/checkin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(checkinData),
      })
        .then(response => {
          if (response.ok) {
              navigate("/", { state: { checkinSuccess: true } });
            } else {
              setCheckinStatus('error');
            }
          })
        .catch(error => {
          console.error('Fehler beim Einchecken:', error);
          setCheckinStatus('error');
        });
  };

  return (
    <div className="checkin-container">
      <h1>Check-In</h1>
      <p>Herzlich Willkommen am Sonsberg.<br />Bevor ihr sicher am SonsBerg klettern könnt, lest bitte die <a href="https://sv-sonsbeck.de/index.php/klimpansen/nutzung-kletterfels/kletter-und-nutzungsordnung" target="_blank" rel="noopener noreferrer">
          Nutzungsbedingungen
          </a> und checkt mit Hilfe dieses Formular ein:</p>
      <p style={{ textAlign: 'right', fontSize: '0.75rem' }}>
        (*) Pflichtangaben
      </p>
      <form onSubmit={handleCheckin}>
        <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="checkin-table-cell">Vorname*:</TableCell>
                <TableCell className="checkin-table-cell">
                  <input
                    type="text"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    required
                    placeholder="Vorname"
                    style={{ borderColor: formErrors.firstName ? 'red' : '' }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="checkin-table-cell">Nachname*:</TableCell>
                <TableCell className="checkin-table-cell">
                  <input
                    type="text"
                    value={lastName}
                    onChange={handleLastNameChange}
                    required
                    placeholder="Nachname"
                    style={{ borderColor: formErrors.lastName ? 'red' : '' }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="checkin-table-cell">Altersgruppe*: </TableCell>
                <TableCell className="checkin-table-cell">
                <input
                    type="radio"
                    value="1"
                    checked={age === "1"}
                    onChange={handleAgeChange}
                    required
                  />
                  unter 14 
                  <input
                    type="radio"
                    value="2"
                    checked={age === "2"}
                    onChange={handleAgeChange}
                    required
                  />
                  14-17
                  <input
                    type="radio"
                    value="3"
                    checked={age === "3"}
                    onChange={handleAgeChange}
                    required
                  />
                  über 18
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="checkin-table-cell">Wohnort*: </TableCell>
                <TableCell className="checkin-table-cell">
                  <input
                    type="text"
                    value={residence}
                    onChange={handleResidenceChange}
                    required
                    placeholder="Wohnort"
                    style={{ borderColor: formErrors.residence ? 'red' : '' }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="checkin-table-cell" colSpan={2} style={{ textAlign: 'center' }}>
                Besteht eine Mitgliedschaft im SV Sonsbeck?*<br />
                  <input
                    type="radio"
                    value="Ja"
                    checked={membership === true}
                    onChange={handleMembershipChange}
                    required
                  />
                  Ja 
                  <input
                    type="radio"
                    value="Nein"
                    checked={membership === false}
                    onChange={handleMembershipChange}
                    required
                  />
                  Nein
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className="checkin-table-cell" style={{color:formErrors.acceptedTerms ? 'red' : ''}}>
                  <input
                    type="checkbox"
                    checked={acceptedTerms}
                    onChange={handleTermsCheckboxChange}
                    style={{ color: formErrors.acceptedTerms ? 'red' : '' }}
                  />
                  Ich akzeptiere die{' '}
                  <a
                    href="https://sv-sonsbeck.de/index.php/klimpansen/nutzung-kletterfels/kletter-und-nutzungsordnung"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Nutzungsbedingungen
                  </a>{' '}
                  vom SonsBerg*
                </TableCell>
              </TableRow>
              {newsletterOn && (
              <TableRow>
                <TableCell colSpan={2} className="checkin-table-cell">
                  <input
                    type="checkbox"
                    checked={newsletter || false}
                    onChange={handleNewsletterCheckboxChange}
                  />
                  Ich möchte gerne über Neuigkeiten vom SonsBerg informiert werden.
                </TableCell>
              </TableRow>
              )}
              {newsletter && newsletterOn && (
                <TableRow>
                  <TableCell className="checkin-table-cell">Email-Adresse: </TableCell>
                  <TableCell className="checkin-table-cell">
                    <input
                      type="email"
                      value={newsletterEmail || ''}
                      onChange={handleNewsletterEmailChange}
                      placeholder="E-Mail-Adresse"
                    />
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className="checkin-table-cell" colSpan={2} style={{ textAlign: 'center' }}>
                <Button type="submit" variant="contained" color="primary" className="details-button">
                      <TaskAlt />&nbsp;Einchecken
                    </Button>
                </TableCell>
              </TableRow>
              {checkinStatus === 'error' && (
                <TableRow>
                  <TableCell colSpan={2} className="checkin-error" style={{color:'red'}}>
                    Fehler beim Einchecken. Bitte überprüfe deine Eingaben:
                    <ul>
                      {formErrors.acceptedTerms && <li>Nutzungsbedingungen</li>}
                      {formErrors.firstName && <li>Vorname</li>}
                      {formErrors.lastName && <li>Nachname</li>}
                      {formErrors.residence && <li>Wohnort</li>}
                      {formErrors.age && <li>Altersgruppe</li>}
                      {formErrors.membership && <li>Mitgliedschaft</li>}
                    </ul>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        </form>
      {token === null && (
      <p className="checkin-register-link">
        Noch keinen Account? <Link to="/register">Jetzt registrieren</Link>
      </p>
      ) }
    </div>
  );
};

export default Checkin;