import React, { useState, useEffect } from 'react';
import config from './config';
import './css/sonsberg.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import Clear from '@mui/icons-material/Clear';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

const AdminNewsletterScreen = () => {
  const [loading, setLoading] = useState(true);
  const [newsletterData, setNewsletterData] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchNewsletterData = async () => {
      try {
        const response = await fetch(`${serverUrl}:${serverPort}/be/user/getAllNewsletters`, {
            method: 'POST',
            headers: {
                'Authorization': `${token}`
            }, 
        });
        if (response.ok) {
          const data = await response.json();
          setNewsletterData(data.newsletterData);
        }
        setLoading(false);
      } catch (error) {
        console.error('Fehler beim Abrufen der Newsletter-Daten:', error);
        setLoading(false);
      }
    };
    fetchNewsletterData();
  }, []);

  const handleDeleteEntry = async (id) => {
    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/user/deleteNewsletter/${id}`, {
        method: 'POST',
        headers: {
            'Authorization': `${token}`
        }, 
  });
      if (response.ok) {
        // Filtere den gelöschten Eintrag aus der lokalen State-Liste
        setNewsletterData((prevData) => prevData.filter((entry) => entry._id !== id));
      }
    } catch (error) {
      console.error('Fehler beim Löschen des Eintrags:', error);
    }
  };

  return (
    <div className="checkin-container">
      <h1>Newsletter Abonnenten</h1>
      {loading ? (
        <div>Lade Daten...</div>
      ) : (
        <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="checkin-table-header">Vorname</TableCell>
                <TableCell className="checkin-table-header">Nachname</TableCell>
                <TableCell className="checkin-table-header">Email-Adresse</TableCell>
                <TableCell className="checkin-table-header">Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsletterData.map((entry) => (
                <TableRow key={entry._id}>
                  <TableCell className="checkin-table-cell">{entry.firstName}</TableCell>
                  <TableCell className="checkin-table-cell">{entry.lastName}</TableCell>
                  <TableCell className="checkin-table-cell">{entry.newsletterEmail}</TableCell>
                  <TableCell className="checkin-table-cell">
                  <Button onClick={() => handleDeleteEntry(entry._id)} type="submit" variant="contained" color="primary" className="admin-button"><Clear /></Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default AdminNewsletterScreen;
