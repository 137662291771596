import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div>
      <h1>Oops! Ein Fehler ist aufgetreten.</h1>
      <h2>Es tut uns leid, aber die angeforderte Seite konnte nicht gefunden werden.</h2>
      <p className="register-link">
        Hier gehts zurück zur <Link to="/">Startseite</Link>
      </p>
      {/* Hier können Sie zusätzliche Informationen oder Anweisungen für den Benutzer hinzufügen */}
    </div>
  );
};

export default ErrorPage;
