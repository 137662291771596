// config.js
const config = {

/** LOCALHOST */
//  serverUrl : "http://localhost",
//  serverUrl : "http://192.168.1.3",
//  serverPort : "8080",

/** TEST ONLINE */
//  serverUrl : "http://212.227.173.74",
//  serverPort : "8080",

/** PROD */
  serverUrl : "https://app.sv-sonsbeck.de", 
  serverPort : "443",  

  newsletterOn : true,
  feedbackTimer : 1000,
  difficultyScaleUIAA : 'UIAA',
  difficultyScaleFrench : 'French',
  difficultyScaleYDS : 'YDS',
  difficultyScaleGB : 'GB',
  difficultyOrder : {
    '3-': 0, '3': 1, '3+': 2, '4-': 3, '4-/4': 3.5, '4': 4, '4+': 5, '5-': 6, '5': 7, '5/5+': 7.5, '5+': 8, '6-': 9, '6': 10, '6/6+': 10.5,
    '6+': 11, '7-': 12, '7': 13, '7/7+': 13.5, '7+': 14, '7+/8-': 14.5, '8-': 15, '8-/8': 15.5, '8': 16, '8/8+': 16.5, '8+': 17, '9-': 18, '9': 19, '': 20
  },
  difficultyMappingFrench : {
    '3-': '3a', '3': '3a+', '3+': '3b', '4-': '3c', '4-/4': '3c+', '4': '4a', '4+': '4b', '5-': '4b+', '5': '4c', '5/5+': '4c+', '5+': '5a', '6-': '5b', '6': '5c', '6/6+': '5c+',
    '6+': '6a', '7-': '6a+', '7': '6b', '7/7+': '6b', '7+': '6b+', '7+/8-': '6b+', '8-': '6c', '8-/8': '6c', '8': '6c+', '8/8+': '6c+', '8+': '7a', '9-': '7b', '9': '7c', '': ''
  },
  difficultyMappingYDS : {
    '3-': '5.3', '3': '5.3/5.4', '3+': '5.4', '4-': '5.4/5.5', '4-/4': '5.4/5.5', '4': '5.5', '4+': '5.6', '5-': '5.6/5.7', '5': '5.7', '5/5+': '5.7/5.8', '5+': '5.8', '6-': '5.9', '6': '5.10a', '6/6+': '5.10a/5.10b',
    '6+': '5.10b', '7-': '5.10c', '7': '5.10d', '7/7+': '5.10d/5.11a', '7+': '5.11a', '7+/8-': '5.11a/5.11b', '8-': '5.11b', '8-/8': '5.11b/5.11c', '8': '5.11c', '8/8+': '5.11c/5.11d', '8+': '5.11d', '9-': '5.12a', '9': '5.12c', '': ''
  },
  difficultyMappingGB : {
    '3-': '3b', '3': '3c', '3+': '3c/4a', '4-': '3c/4a', '4-/4': '4a', '4': '4a', '4+': '4b', '5-': '4b/4c', '5': '4c', '5/5+': '4c/5a', '5+': '5a', '6-': '5a', '6': '5a', '6/6+': '5b',
    '6+': '5b', '7-': '5b', '7': '5c', '7/7+': '5c', '7+': '5c', '7+/8-': '5c', '8-': '6a', '8-/8': '6a', '8': '6a', '8/8+': '6a', '8+': '6a', '9-': '6b', '9': '6c', '': ''
  },
  colorMapping : {
    'schwarz': '#000000',
    'weiß': '#ffffff',
    'orange': '#ff8800',
    'rot': '#ff0000',
    'grün': '#00ff00',
    'blau': '#0000ff',
    'gelb': '#ffff00',
    'grau': '#888888',
    'Zebra': '#ffffff',
    'Riss / Struktur': '',
  }, 

  colorCircleStyle : {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '8px',
    border: '1px solid black',
  },
  
 zebraCircleContainerStyle : {
    display: 'inline-flex',
  },
  
  zebraCircleStyle1 : {
    transform: 'translateX(20px)',
  },

  zebraCircleStyle2 : {
    transform: 'translateX(-15px)',
  },

};
  
  export default config;
  