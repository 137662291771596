import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

function DeleteAccountScreen({ onLogout }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const handleDeleteAccount = async (event) => {
    event.preventDefault();
    if (confirmDelete) {        
        try {
            const response = await fetch(`${serverUrl}:${serverPort}/be/user/deleteUserByToken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
                },
            });
            onLogout();
            navigate("/");
        } catch(error)  {
            console.error('Fehler beim Auslesen der Userdaten:', error);
        };
    }
  };

  return (
      <div className="checkin-container">
      <h1>Account löschen</h1>
      <br />
      <p>Beim Löschen werden sämtliche Informationen gelöscht. Du kannst dich nicht mehr einloggen und dein Kletter-Fortschritt, ist nicht mehr verfügbar.</p>
      <p>Du kannst dich jederzeit neu registrieren.</p><br />
      <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableBody>
            
              <TableRow>
                <TableCell className="checkin-table-cell">Sonsberg Account löschen</TableCell>
                <TableCell className="checkin-table-cell">
                {!confirmDelete && (<Button type="submit" variant="contained" color="primary" className="details-button" onClick={() => setConfirmDelete(true)}>
                      &nbsp;Account Löschen</Button>)}&nbsp;
                </TableCell>
              </TableRow>
              
                {confirmDelete && (
            <TableRow>
                <TableCell className="checkin-table-cell">Bist du sicher?</TableCell>
                <TableCell className="checkin-table-cell">
                    <Button type="submit" variant="contained" color="primary" className="details-button" onClick={handleDeleteAccount}>
                      &nbsp;Ja</Button>&nbsp;
                    <Button type="submit" variant="contained" color="primary" className="details-button" onClick={() => setConfirmDelete(false)}>
                      &nbsp;Abbrechen</Button>
                </TableCell>
              </TableRow>
              )}
            </TableBody>
        </Table>
    </TableContainer>
    </div>
  );
}

export default DeleteAccountScreen;
