import React, { useEffect, useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from './config.js';
import './css/sonsberg.css';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const feedbackTimer = config.feedbackTimer;
const Scale_French = config.difficultyScaleFrench;
const Scale_YDS = config.difficultyScaleYDS;
const Scale_GB = config.difficultyScaleGB;
const difficultyMappingFrench = config.difficultyMappingFrench;
const difficultyMappingYDS = config.difficultyMappingYDS;
const difficultyMappingGB = config.difficultyMappingGB;

const RoutesTable = ({ filterOptions, difficultyScale }) => {
  const [loading, setLoading] = useState(true); // Zustand für den Ladezustand
  const [routes, setRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState(routes);
  const [sort, setSort] = useState(() => {
    const storedSort = localStorage.getItem('sort');
    return storedSort || 'routenumber';
  });
  const [sortOrder, setSortOrder] = useState(() => {
    const storedSortOrder = localStorage.getItem('sortOrder');
    return JSON.parse(storedSortOrder) || '';
  });
  const [mappings, setMappings] = useState([]);
  const [showFeedback, setShowFeedback] = useState({});
  const token = localStorage.getItem('token');
  const difficultyOrder = config.difficultyOrder;
  const colorMapping = config.colorMapping;
  const colorCircleStyle = config.colorCircleStyle;
  const zebraCircleContainerStyle = config.zebraCircleContainerStyle;
  const zebraCircleStyle1 = config.zebraCircleStyle1;
  const zebraCircleStyle2 = config.zebraCircleStyle2;
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('sort', sort);

    if (sortOrder[sort] != undefined) {
      localStorage.setItem('sortOrder', JSON.stringify(sortOrder));
    }
  }, [sort, sortOrder]);

  useEffect(() => {
    const storedSort = localStorage.getItem('sort');
    const storedSortOrder = localStorage.getItem('sortOrder');
    if (storedSort) {
      setSort(storedSort);
    }
    if (storedSortOrder) {
      setSortOrder(JSON.parse(storedSortOrder));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Rufe die Routen vom Server ab
        const routesResponse = await fetch(`${serverUrl}:${serverPort}/be/routes/getall`);
        const routesData = await routesResponse.json();
        setRoutes(routesData);

        // Rufe die Mappings vom Server ab
        const mappingResponse = await fetch(`${serverUrl}:${serverPort}/be/routes/getMappingForUser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          },
        });
        const mappingData = await mappingResponse.json();
        setMappings(mappingData);

        // Setze loading auf false, wenn die Daten abgerufen und verarbeitet wurden
        setLoading(false);
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        // Setze loading auf false, falls ein Fehler auftritt
        setLoading(false);
      }
    };
    fetchData();

    
    if (!loading) {
      // Daten wurden geladen, wende Filter und Sortierung an
      applyFilter();
    //  handleSort(sort, filteredRoutes);
    }
  }, [token, filterOptions, difficultyScale]);

  useEffect(() => {
    if (!loading && routes.length > 0 && filterOptions !== null) {
      applyFilter();
//      handleSort(sort, filteredRoutes);
    }
  }, [loading, routes, filterOptions]);

  const applyFilter = () => {
    const filteredRoutes = handleFilter(filterOptions);
    handleSort(sort, filteredRoutes, sortOrder[sort]);
  };

  const handleFilter = (filterOptions) => {
    if (filterOptions === null || filterOptions === '') {
      setFilteredRoutes(routes);
      return null;
    } else {
      const { difficultyFrom, difficultyTo, topropeClimbed, vorstiegClimbed } = filterOptions;
      
      // Filtere die Routen basierend auf den Filterkriterien
      const filteredRoutes = routes.filter((route) => {
        const routeDifficulty = difficultyOrder[route.difficulty];
        const mapping = getMappingForRoute(route._id);
        const routeToprope = mapping ? mapping.toprope : false;
        const routeVorstieg = mapping ? mapping.vorstieg : false;
        
        return (
          (difficultyFrom === null || difficultyFrom === '' || routeDifficulty >= difficultyFrom) &&
          (difficultyTo === null || difficultyTo === '' || routeDifficulty <= difficultyTo) &&
          (topropeClimbed === null || topropeClimbed === 'all' || (routeToprope && topropeClimbed === 'true') || (!routeToprope && topropeClimbed === 'false')) &&
          (vorstiegClimbed === null || vorstiegClimbed === 'all' || (routeVorstieg && vorstiegClimbed === 'true') || (!routeVorstieg && vorstiegClimbed === 'false'))
        );
      });
    
      // Setze die gefilterten Routen im Zustand
      setFilteredRoutes(filteredRoutes);

      return filteredRoutes;
    }
  };

  const handleVorstiegChange = (event, route) => {
    const mapping = mappings.find(mapping => mapping.route_id === route._id);
    const isChecked = event.target.checked;
    const updatedMappings = [...mappings];

    if (mapping) {
      // Mapping exists, update the entry
      const updatedMapping = { ...mapping, vorstieg: isChecked };

      fetch(`${serverUrl}:${serverPort}/be/routes/update-mapping/${mapping._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify(updatedMapping),
      })
        .then(response => response.json())
        .then(data => {
          const index = updatedMappings.findIndex(m => m.route_id === route._id);
          updatedMappings[index] = updatedMapping;
          setMappings(updatedMappings);

          // Show the feedback for this route for 2 seconds
          setShowFeedback(prevState => ({ ...prevState, [route._id]: true }));
          setTimeout(() => {
            setShowFeedback(prevState => ({ ...prevState, [route._id]: false }));
          }, feedbackTimer);
        })
        .catch(error => console.error('Error updating database entry:', error));
    } else {
      // Mapping doesn't exist, create a new entry
      const newMapping = {
        route_id: route._id,
        vorstieg: isChecked,
      };

      fetch(`${serverUrl}:${serverPort}/be/routes/create-mapping`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify(newMapping),
      })
        .then(response => response.json())
        .then(data => {
          newMapping._id = data._id;
          setMappings([...mappings, newMapping]);

          setShowFeedback(prevState => ({ ...prevState, [route._id]: true }));
          setTimeout(() => {
            setShowFeedback(prevState => ({ ...prevState, [route._id]: false }));
          }, feedbackTimer);
        })
        .catch(error => console.error('Error creating database entry:', error));
    }
  };

  const handleTopropeChange = (event, route) => {
    const mapping = mappings.find(mapping => mapping.route_id === route._id);
    const isChecked = event.target.checked;
    const updatedMappings = [...mappings];

    if (mapping) {
      // Mapping existiert bereits, aktualisiere den Eintrag
      const updatedMapping = { ...mapping, toprope: isChecked };

      fetch(`${serverUrl}:${serverPort}/be/routes/update-mapping/${mapping._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify(updatedMapping),
      })
        .then(response => response.json())
        .then(data => {
          const index = updatedMappings.findIndex(m => m.route_id === route._id);
          updatedMappings[index] = updatedMapping;
          setMappings(updatedMappings);

          // Show the feedback for this route for 2 seconds
          setShowFeedback(prevState => ({ ...prevState, [route._id]: true }));
          setTimeout(() => {
            setShowFeedback(prevState => ({ ...prevState, [route._id]: false }));
          }, feedbackTimer);
        })
        .catch(error => console.error('Fehler beim Aktualisieren des Datenbankeintrags:', error));
    } else {
      // Mapping existiert noch nicht, lege einen neuen Eintrag an
      const newMapping = {
        token: token,
        route_id: route._id,
        toprope: isChecked,
      };

      fetch(`${serverUrl}:${serverPort}/be/routes/create-mapping`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify(newMapping),
      })
        .then(response => response.json())
        .then(data => {
          newMapping._id = data._id;
          setMappings([...mappings, newMapping]);
          
          // Show the feedback for this route for 2 seconds
          setShowFeedback(prevState => ({ ...prevState, [route._id]: true }));
          setTimeout(() => {
            setShowFeedback(prevState => ({ ...prevState, [route._id]: false }));
          }, feedbackTimer);
        })
        .catch(error => console.error('Fehler beim Erstellen des Datenbankeintrags:', error));
    }
  };

  const getMappingForRoute = routeId => {
    const mapping = mappings.find(mapping => mapping.route_id === routeId);
    return mapping ? mapping : null;
  };

  const handleClick = routeId => {
    navigate(`/routeDetails/${routeId}`);
  };

  const handleMouseEnter = event => {
    event.target.style.cursor = 'pointer';
  };

  const handleMouseLeave = event => {
    event.target.style.cursor = 'default';
  };

  const handleSort = (column, routesToSort = filteredRoutes, order) => {
    if (order === undefined || order === null || order === "") {
      order = "asc";
    }
//    const storedSortedRoutes = localStorage.getItem('sortedRoutes');
//    const sortedRoutes = storedSortedRoutes ? storedSortedRoutes : Array.isArray(routesToSort) ? [...routesToSort] : [...routes];
    const sortedRoutes = Array.isArray(routesToSort) ? [...routesToSort] : [...routes];

    if (column === 'routenumber') {
      setSort('routenumber');
        sortedRoutes.sort((a, b) => {
          const valueA = Number(a[column])
          const valueB = Number(b[column])
          return valueA - valueB;
        });
    } else {
      setSort(column === 'color' ? 'color' : 'routename');
      sortedRoutes.sort((a, b) => {
        const valueA = String(a[column]).toLowerCase()
        const valueB = String(b[column]).toLowerCase()
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
      });
    }
  
    if (column === 'difficulty') {
      setSort('difficulty');
      sortedRoutes.sort((a, b) => compareDifficulty(a[column], b[column]))
    }
  
    if (column === 'toprope') {
      setSort('toprope');
      sortedRoutes.sort((a, b) => {
        const mappingA = mappings.find(mapping => mapping.route_id === a._id)
        const mappingB = mappings.find(mapping => mapping.route_id === b._id)
        const topropeA = mappingA ? mappingA.toprope : false
        const topropeB = mappingB ? mappingB.toprope : false
        return topropeA === topropeB ? 0 : topropeA ? -1 : 1;
      });
    }

    if (column === 'vorstieg') {
      setSort('vorstieg');
      sortedRoutes.sort((a, b) => {
        const mappingA = mappings.find(mapping => mapping.route_id === a._id)
        const mappingB = mappings.find(mapping => mapping.route_id === b._id)
        const vorstiegA = mappingA ? mappingA.vorstieg : false
        const vorstiegB = mappingB ? mappingB.vorstieg : false
        return vorstiegA === vorstiegB ? 0 : vorstiegA ? -1 : 1;
      });
    }

    if (order === 'desc') {
      sortedRoutes.reverse();
      setSortOrder({ ...sortOrder, [column]: 'desc' });
    } else {
      setSortOrder({ ...sortOrder, [column]: 'asc' });
    }
    setFilteredRoutes(sortedRoutes);
    localStorage.setItem('sortedRoutes', sortedRoutes);
  };

  const compareDifficulty = (a, b) => {
    return difficultyOrder[a] - difficultyOrder[b];
  };

  const getDifficultyLabel = (routeDifficulty) => {
    if (difficultyScale === Scale_French) {
      return difficultyMappingFrench[routeDifficulty];
    }
    if (difficultyScale === Scale_YDS) {
      return difficultyMappingYDS[routeDifficulty];
    }
    if (difficultyScale === Scale_GB) {
      return difficultyMappingGB[routeDifficulty];
    }
    return routeDifficulty;
  };

  return (
    <TableContainer component={Paper} className="route-table-container">
      {loading ? (
        <div>Lade Daten...</div>
      ) : (
        /*
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {filteredRoutes.map(route => (
          <table key={route._id} className="route-table" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleClick(route._id)}>
            <tbody>
              <tr>
                <td className="route-table-name" colSpan={2}>{route.routename}</td>
                <td className="route-table-color">
                {route.color === 'Zebra' ? (
                      <div style={zebraCircleContainerStyle}>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'black',
                            ...zebraCircleStyle, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'white', // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                      </div>
                    ) : route.color === 'Riss / Struktur' ? (
                      <div>Riss</div>
                    ) : (
                      // Standard-Kreis für andere Farben
                      <div
                        style={{
                          ...colorCircleStyle,
                          backgroundColor: colorMapping[route.color],
                        }}
                      ></div>
                    )}
                  </td>
              </tr>
              <tr>
                <td className="route-table-difficulty">{route.difficulty}</td>
                <td className="route-table-trvs" style={{ color: getMappingForRoute(route._id) && getMappingForRoute(route._id).toprope ? 'green' : '' }}>
                {getMappingForRoute(route._id) && getMappingForRoute(route._id).toprope ? 'TR' : ''}
                </td>
                <td className="route-table-trvs" style={{ color: getMappingForRoute(route._id) && getMappingForRoute(route._id).vorstieg ? 'green' : '' }}>
                {getMappingForRoute(route._id) && getMappingForRoute(route._id).vorstieg ? 'VS' : ''}
                </td>
              </tr>
            </tbody>
          </table>
      ))}
      </div>
*/
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#DBDCD7' }}>
              <TableCell className="table-cell-header" onClick={() => handleSort('routename', filteredRoutes, sortOrder['routename'] === 'asc' ? 'desc' : 'asc')}>
                Name der Route
              </TableCell>
              <TableCell className="table-cell-header" onClick={() => handleSort('color', filteredRoutes, sortOrder['color'] === 'asc' ? 'desc' : 'asc')}>
                Farbe
              </TableCell>
              <TableCell className="table-cell-header" onClick={() => handleSort('difficulty', filteredRoutes, sortOrder['difficulty'] === 'asc' ? 'desc' : 'asc')}>
                {difficultyScale}-Grad
              </TableCell>
              <TableCell className="table-cell-header" onClick={() => handleSort('toprope', filteredRoutes, sortOrder['toprope'] === 'asc' ? 'desc' : 'asc')}>
                <img src="./img/toprope_icon.png" width="25" />Toprope
              </TableCell>
              <TableCell className="table-cell-header" onClick={() => handleSort('vorstieg', filteredRoutes, sortOrder['vorstieg'] === 'asc' ? 'desc' : 'asc')}>
              <img src="./img/vorstieg_icon.png" width="25" />Vorstieg
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRoutes.map(route => (
              <TableRow key={route._id} style={showFeedback[route._id]? { backgroundColor: 'lightgreen' } : {}}>
                <TableCell className="table-cell-name"
                  onClick={() => handleClick(route._id)}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {getMappingForRoute(route._id) && getMappingForRoute(route._id).project && <BookmarkIcon style={{color: '#D10A11'}} / >}&nbsp;{route.routename}
                </TableCell>
                <TableCell className="table-cell"
                  onClick={() => handleClick(route._id)}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ textAlign: 'center' }}
                >
                  {route.color === 'Zebra' ? (
                      <div style={zebraCircleContainerStyle}>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'black',
                            ...zebraCircleStyle1, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'white',
                            ...zebraCircleStyle2, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                      </div>
                    ) : route.color === 'Riss / Struktur' ? (
                      <div>Riss</div>
                    ) : (
                      // Standard-Kreis für andere Farben
                      <div
                        style={{
                          ...colorCircleStyle,
                          backgroundColor: colorMapping[route.color],
                        }}
                      ></div>
                    )}
                </TableCell>
                <TableCell className="table-cell"
                  onClick={() => handleClick(route._id)}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {getDifficultyLabel(route.difficulty)}
                </TableCell>
                <TableCell className="table-cell">
                  <Checkbox
                    id={`topropeCheck_${route._id}`}
                    checked={getMappingForRoute(route._id) && getMappingForRoute(route._id).toprope || false}
                    onChange={event => handleTopropeChange(event, route)}
                   
                  />
                </TableCell>
                <TableCell className="table-cell">
                  <Checkbox
                    id={`vorstiegCheck_${route._id}`}
                    checked={getMappingForRoute(route._id) && getMappingForRoute(route._id).vorstieg || false}
                    onChange={event => handleVorstiegChange(event, route)}
                    
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        

      )}
  </TableContainer>
  );
};

export default RoutesTable;
