import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Login from '@mui/icons-material/Login';
import './css/sonsberg.css';
import config from './config.js';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

const LoginScreen = ({ setToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();
  const usernameRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
    usernameRef.current.focus();
  }, [navigate]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.target.name === 'username') {
        document.getElementById('password').focus();
      } else if (e.target.name === 'password') {
        handleLogin();
      }
    }
  };

  const handleLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setToken(data.token);
        setLoginError(false);
        localStorage.setItem('token', data.token);
        localStorage.setItem('filterOptions', data.filterOptions);
        navigate('/');
      } else {
        console.error('Login fehlgeschlagen:', data.message);
        setLoginError(true);
      }
    } catch (error) {
      console.error('Fehler beim Login:', error);
      setLoginError(true);
    }
  };

  return (
    <form onSubmit={handleLogin}>
    <div className="login-container">
      <h2>Login</h2>
      {loginError && <p className="error-message">Login fehlgeschlagen</p>}
      <input
        type="text"
        placeholder="Username"
        name="username"
        value={username}
        onChange={handleUsernameChange}
        onKeyDown={handleKeyDown}
        ref={usernameRef}
        className={loginError ? 'input-error' : ''}
      />
      <input
        type="password"
        placeholder="Password"
        name="password"
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown}
        className={loginError ? 'input-error' : ''}
        id="password"
      />
       {loginError && <Link to="/forgotPassword">Passwort vergessen</Link>}
       {loginError && <br />}
        <Button type="submit" variant="contained" color="primary" className="details-button">
          <Login />&nbsp;Login
        </Button>
      <p className="register-link">
        Noch keinen Account? <Link to="/register">Jetzt registrieren</Link>
      </p>
    </div>
    </form>
  );
};

export default LoginScreen;
