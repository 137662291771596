import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableCell, TableContainer, TableHead, TableRow, Button, FormControl, InputLabel, Select, MenuItem, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import FitlerList from '@mui/icons-material/FilterList';
import './css/sonsberg.css';

const FilterScreen = ({ filterOptions, onApplyFilter }) => {
  const [difficultyFrom, setDifficultyFrom] = useState(filterOptions?.difficultyFrom || '');
  const [difficultyTo, setDifficultyTo] = useState(filterOptions?.difficultyTo || '');
  const [topropeClimbed, setTopropeClimbed] = useState(filterOptions?.topropeClimbed || 'all');
  const [vorstiegClimbed, setVorstiegClimbed] = useState(filterOptions?.vorstiegClimbed || 'all');
  const [isFilterDeleted, setIsFilterDeleted] = useState(false);
  const navigate = useNavigate();

  const handleApplyFilter = () => {
    const newFilterOptions = { difficultyFrom, difficultyTo, topropeClimbed, vorstiegClimbed };
    onApplyFilter(newFilterOptions);
    if (!isFilterDeleted) {
      navigate('/routes');
    }
  };

  const handleDeleteFilter = () => {
    setDifficultyFrom('');
    setDifficultyTo('');
    setTopropeClimbed('all');
    setVorstiegClimbed('all');
    setIsFilterDeleted(true);
    localStorage.removeItem('sort');
    localStorage.removeItem('sortOrder')
  };

  useEffect(() => {
    if (isFilterDeleted) {
      handleApplyFilter();
      setIsFilterDeleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterDeleted]);

  const generateDifficultyOptions = () => {
    // Generieren Sie die Optionen für die Schwierigkeitsgrade
    const difficulties = [
      '3-', '3', '3+', '4-', '4', '4+', '5-', '5', '5+', '6-', '6', '6+', '7-', '7', '7+', '8-', '8', '8+', '9-', '9'
    ];

    if (difficultyFrom === '') {
      return difficulties.map((difficulty, index) => (
        <MenuItem key={index} value={index}>{difficulty}</MenuItem>
      ));
    } else {
      const startIndex = parseInt(difficultyFrom, 10);
      return difficulties.slice(startIndex).map((difficulty, index) => (
        <MenuItem key={startIndex + index} value={startIndex + index}>{difficulty}</MenuItem>
      ));
    }
  };

  return (
    <div className="checkin-container">
      <h1>Filter Einstellen</h1>
      <TableContainer className="checkin-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="checkin-table-cell">Toprope <img src="./img/toprope_icon.png" width="25" /></TableCell>
              <TableCell className="checkin-table-cell">Vorstieg <img src="./img/vorstieg_icon.png" width="25" /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="checkin-table-cell">
                <RadioGroup
                  aria-label="topropeClimbed"
                  name="topropeClimbed"
                  className="filter-radio"
                  value={topropeClimbed}
                  onChange={(e) => setTopropeClimbed(e.target.value)}
                >
                  <FormControlLabel value="all" control={<Radio />} label="Alle" />
                  <FormControlLabel value="true" control={<Radio />} label="Geklettert" />
                  <FormControlLabel value="false" control={<Radio />} label="Nicht geklettert" />
                </RadioGroup>
              </TableCell>
              <TableCell className="checkin-table-cell">
                <RadioGroup
                  aria-label="vorstiegClimbed"
                  name="vorstiegClimbed"
                  className="filter-radio"
                  value={vorstiegClimbed}
                  onChange={(e) => setVorstiegClimbed(e.target.value)}
                >
                  <FormControlLabel value="all" control={<Radio />} label="Alle" />
                  <FormControlLabel value="true" control={<Radio />} label="Geklettert" />
                  <FormControlLabel value="false" control={<Radio />} label="Nicht geklettert" />
                </RadioGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="checkin-table-cell" colSpan={2} align="center">UIAA-Schwierigkeitsgrad</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="checkin-table-cell">
                <FormControl variant="outlined" className="filter-input">
                  <InputLabel htmlFor="difficulty-from">von</InputLabel>
                  <Select
                    value={difficultyFrom}
                    onChange={(e) => setDifficultyFrom(e.target.value)}
                    label="UIAA-Grad von"
                    className="filter-select"
                    inputProps={{
                      name: 'difficulty-from',
                      id: 'difficulty-from',
                    }}
                  >
                    <MenuItem value="">Alle</MenuItem>
                    {generateDifficultyOptions()}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell className="checkin-table-cell">
                <FormControl variant="outlined" className="filter-input">
                  <InputLabel htmlFor="difficulty-to">bis</InputLabel>
                  <Select
                    value={difficultyTo}
                    onChange={(e) => setDifficultyTo(e.target.value)}
                    label="UIAA-Grad bis"
                    className="filter-select"
                    inputProps={{
                      name: 'difficulty-to',
                      id: 'difficulty-to',
                    }}
                  >
                    <MenuItem value="">Alle</MenuItem>
                    {generateDifficultyOptions()}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="checkin-table-cell" align="right">
              <Button type="submit" variant="contained" color="primary" className="details-button" onClick={handleApplyFilter}>
                  <FitlerList />&nbsp;Filter anwenden
                </Button>
                </TableCell>
                <TableCell className="checkin-table-cell" align="left">
                  <Button type="submit" variant="contained" color="primary"  style={{ color: 'white' }}  className="details-button" onClick={handleDeleteFilter}>
                  <Clear />&nbsp;Filter löschen
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FilterScreen;
