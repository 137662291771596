import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

const CheckinReportScreen = () => {
  const [checkinsData, setCheckinsData] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchCheckinsData = async () => {
        try {
            const response = await fetch(`${serverUrl}:${serverPort}/be/user/getCheckinsForReport`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
              },
            });
            const data = await response.json();
            if (data && data.checkins && data.checkins.length >0) {
                setCheckinsData(data.checkins.reverse());
            }
          } catch (error) {
            console.error('Fehler beim Update des Users:', error);
          }
    };

    fetchCheckinsData();
  }, []);

  const getChartData = () => {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
  
    const daysInLastMonth = new Array(32).fill(0).map((_, index) => {
      const day = new Date(lastMonth);
      day.setDate(day.getDate() + index);
      return day;
    });
  
    const checkinsByDay = daysInLastMonth.filter((day) => {
      const dayData = checkinsData.find((checkin) => {
        const createdAtDate = new Date(checkin.createdAt);
        return createdAtDate.toISOString().split('T')[0] === day.toISOString().split('T')[0];
      });
      return dayData !== undefined;
    }).map((day) => ({
      day: `${getDayName(day.getDay())} (${formatDate(day)})`,
      count: checkinsData.filter((checkin) => {
        const createdAtDate = new Date(checkin.createdAt);
        return createdAtDate.toISOString().split('T')[0] === day.toISOString().split('T')[0];
      }).length,
    }));
  
    return checkinsByDay.reverse();
  };
  
  const getDayName = (dayOfWeek) => {
    const dayNames = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    return dayNames[dayOfWeek];
  };

  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('de-DE', options);
  };

  const chartData = getChartData();
  const maxCount = Math.max(...chartData.map((entry) => entry.count));
  const scaleFactor = 100 / maxCount;

  const convertToCSV = (data) => {
    const csvRows = [];
  
    // Filter unwanted fields
    const filteredData = data.map(entry => {
      const { _id, updatedAt, __v, ...filteredEntry } = entry;
      filteredEntry.createdAt = new Date(filteredEntry.createdAt);
      if (filteredEntry.age === "1") {
        filteredEntry.age = "U14"
      } else if(filteredEntry.age === "2") {
        filteredEntry.age = "14-18"
      } else if(filteredEntry.age === "3") {
        filteredEntry.age = "Ü18"
      } else {
        filteredEntry.age = "unknown"
      }
      return filteredEntry;
    });
  
    // Hier die ursprünglichen und gewünschten Spaltennamen angeben
    const columnMapping = {
      acceptedTerms: 'Zustimmung erteilt',
      firstName: 'Vorname',
      lastName: 'Nachname',
      age: 'Altersgruppe',
      residence: 'Wohnort',
      membership: 'Mitgliedschaft',
      newsletter: 'Newsletter',
      createdAt: 'Erstellt am',
    };
  
    // Funktion zum Ersetzen von true/false durch Ja/Nein
    const replaceBooleanValue = (value) => (value ? 'Ja' : 'Nein');
  
    // Header row
    const headers = Object.values(columnMapping);
    csvRows.push(headers.join(','));
  
    // Data rows
    for (const entry of filteredData) {
      const values = Object.keys(columnMapping).map(column => {
        const fieldValue = entry[column];
        if (fieldValue instanceof Date) {
          return fieldValue.toISOString().split('T')[0];
        }
        // Wenn es sich um einen Boolean-Wert handelt, ersetze true/false durch Ja/Nein
        if (typeof fieldValue === 'boolean') {
          return replaceBooleanValue(fieldValue);
        }
        return fieldValue;
      });
      csvRows.push(values.join(','));
    }
  
    return csvRows.join('\n');
  };

  const downloadCSV = () => {
    const csv = convertToCSV(checkinsData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'checkin_report.csv'; // Hier kannst du den Dateinamen ändern
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Hilfsfunktion, um Daten in ein Byte-Array zu konvertieren
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const downloadExcel = () => {
    const csv = convertToCSV(checkinsData);
    const csvData = new Blob([csv], { type: 'text/csv' });
  
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(csv.split('\n').map(row => row.split(',')));
  
    // Spaltenindex für die Datumsfelder
    const dateColumns = [7]; // Index 7 für 'createdAt'
  
    // Zellentyp für Datumsfelder festlegen
    const dateFormat = 'dd.mm.yyyy';
    dateColumns.forEach(columnIndex => {
      for (let row = 1; row < worksheet.length; row++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: columnIndex });
        const cell = worksheet[cellRef];
        if (cell && cell.t === 's') {
          // Überprüfen, ob der Zellwert eine Zeichenfolge ist (s - string)
          const dateValue = new Date(cell.v);
          if (!isNaN(dateValue)) {
            // Wenn es sich um ein gültiges Datum handelt, setzen wir den Zellwert und den Zellentyp neu
            cell.v = XLSX.utils.date_to_excel(dateValue, { dateNF: dateFormat });
            cell.t = 'd'; // 'd' steht für Datumsformat
          }
        }
      }
    });
  
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Checkin Report');
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  
    // Funktion zum Konvertieren in ein Byte-Array
    const s2ab = s => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf;
    };
  
    const blob = new Blob([s2ab(excelData)], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'checkin_report.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
          <TableRow>
              <TableCell><h2>Checkin Report</h2></TableCell>
              <TableCell style={{textAlign:'right'}}><button onClick={() => downloadCSV()}>CSV Export</button><button onClick={() => downloadExcel()}>Excel Export</button></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell># Checkins</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chartData.map((entry) => (
              <TableRow key={entry.day}>
                <TableCell>{entry.day}</TableCell>
                <TableCell>
                    {entry.count}
                    <div
                    style={{
                      width: `${entry.count * scaleFactor}px`,
                      backgroundColor: '#8884d8',
                      height: '10px',
                      marginTop: '5px',
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CheckinReportScreen;
