import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Checkbox } from '@mui/material';
import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

const AdminUserScreen = () => {
  const [usersData, setUsersData] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchUsersData = async () => {
    try {
    const response = await fetch(`${serverUrl}:${serverPort}/be/user/getAllUsers`, {
        method: 'POST',
        headers: {
            'Authorization': `${token}`
        }, 
        });
        if (response.ok) {
            const data = await response.json();
            if (data && data.users && data.users.length > 0) {
              setUsersData(data.users);
            }
              }
    } catch (error) {
      console.error('Fehler beim Abrufen der Benutzerdaten:', error);
    }
  };

  const handleAdminCheckboxChange = async (userId, isAdmin) => {
    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/user/updateUser/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify({ isAdmin: !isAdmin }),
      });
      const data = await response.json();
      if (data && data.success) {
        // Update the state with the updated user data
        setUsersData(prevState => prevState.map(user => {
          if (user._id === userId) {
            return { ...user, isAdmin: !isAdmin };
          }
          return user;
        }));
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Benutzers:', error);
    }
  };

  const handleSupervisorCheckboxChange = async (userId, isSupervisor) => {
    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/user/updateUser/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify({ isSupervisor: !isSupervisor }),
      });
      const data = await response.json();
      if (data && data.success) {
        // Update the state with the updated user data
        setUsersData(prevState => prevState.map(user => {
          if (user._id === userId) {
            return { ...user, isSupervisor: !isSupervisor };
          }
          return user;
        }));
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Benutzers:', error);
    }
  };

  return (
    <div>
      <h2>Admin User Screen</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>Aufsichtsperson</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={user.isAdmin}
                    onChange={() => handleAdminCheckboxChange(user._id, user.isAdmin)}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={user.isSupervisor}
                    onChange={() => handleSupervisorCheckboxChange(user._id, user.isSupervisor)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdminUserScreen;
