import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const newsletterOn = config.newsletterOn;

const Scale_UIAA = config.difficultyScaleUIAA;
const Scale_French = config.difficultyScaleFrench;
const Scale_YDS = config.difficultyScaleYDS;
const Scale_GB = config.difficultyScaleGB;

const ProfileScreen = ({ updateDifficulty }) => {
  const [newsletterData, setNewsletterData] = useState(null);
  const [newsletterEnabled, setNewsletterEnabled] = useState(false);
  const [user, setUser] = useState(null);
  const [difficultyScale, setDifficultyScale] = useState(config.difficultyScaleUIAA);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    getUserData();
  });

  if (!token) {
    navigate("/");
    return;
  }

  const getUserData = () => {
    fetch(`${serverUrl}:${serverPort}/be/user/getNewsletterByToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        const { newsletterData, user } = data;
        setUser(user);
        if (newsletterData && newsletterData.newsletterEmail) {
            setNewsletterData(newsletterData);
            setNewsletterEnabled(true);
        }
        if (user && user.difficultyScale) {
          setDifficultyScale(user.difficultyScale);
        }
      } else {
        console.log("No data.");
      }
      setLoading(false);
    })
    .catch(error => {
      console.error('Fehler beim Auslesen der Userdaten:', error);
    });
  };
  
  const handleNewsletterToggle = () => {

    if (!newsletterEnabled) {
        fetch(`${serverUrl}:${serverPort}/be/user/insertNewsletter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            body: JSON.stringify({
                newsletterEmail: user.email,
            })
          })
            .then(response => {
                setNewsletterEnabled(true);
            })
            .catch(error => {
              console.error('Fehler beim Abbonieren des Newsletters:', error);
            });
    } else {
        fetch(`${serverUrl}:${serverPort}/be/user/deleteNewsletter/${newsletterData._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
          })
          .then(response => {
            setNewsletterEnabled(false);
          })
            .catch(error => {
              console.error('Fehler beim Löschen des Newsletters:', error);
            });
    }
    
  };

  const handleDifficultyToggle = (event) => {
    const selectedScale = event.target.value;
    fetch(`${serverUrl}:${serverPort}/be/user/updateDifficultyScale`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
      body: JSON.stringify({
        difficultyScale: selectedScale,
      }),
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        setDifficultyScale(selectedScale);
        updateDifficulty(selectedScale);
      }
    })
    .catch(error => {
      console.error('Fehler beim Aktualisieren der Schwierigkeitsskala:', error);
    });
  };
  

  return (
    <div className="checkin-container">
    <h1>Mein Profil</h1>
      <TableContainer component={Paper} className="checkin-table">
        <Table>
          <TableBody>
          <TableRow>
              <TableCell className="checkin-table-cell">Username:</TableCell>
              <TableCell className="checkin-table-cell">{loading ? '' : user.username}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="checkin-table-cell">Email:</TableCell>
              <TableCell className="checkin-table-cell">{loading ? '' : user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="checkin-table-cell">Passwort</TableCell>
              <TableCell className="checkin-table-cell"><Link to="/changePassword">ändern</Link></TableCell>
            </TableRow>
            {newsletterOn && (
            <TableRow>
              <TableCell className="checkin-table-cell">Newsletter erhalten:</TableCell>
              <TableCell className="checkin-table-cell"><FormControlLabel
              control={<Switch checked={newsletterEnabled} onChange={handleNewsletterToggle} />}
              label=""
            /></TableCell>
            </TableRow>
            )}
            <TableRow>
              <TableCell className="checkin-table-cell">Schwierigkeit (Skala):</TableCell>
              <TableCell className="checkin-table-cell">
                <FormControl component="fieldset">
                  <RadioGroup row value={difficultyScale} onChange={handleDifficultyToggle}>
                    <FormControlLabel value={Scale_UIAA} control={<Radio />} label={Scale_UIAA} />
                    <FormControlLabel value={Scale_French} control={<Radio />} label={Scale_French} />
                    <FormControlLabel value={Scale_YDS} control={<Radio />} label={Scale_YDS} />
                    <FormControlLabel value={Scale_GB} control={<Radio />} label={Scale_GB} />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="checkin-table-cell">Account</TableCell>
              <TableCell className="checkin-table-cell"><Link to="/deleteAccount">löschen</Link></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProfileScreen;
