import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button } from '@mui/material';
import config from './config.js';
import './css/sonsberg.css';
import Login from '@mui/icons-material/Login';
import LinkIcon from '@mui/icons-material/Link';
import TaskAlt from '@mui/icons-material/TaskAlt';
import EmojiEventsOutlined from '@mui/icons-material/EmojiEventsOutlined';
import RocketLaunch from '@mui/icons-material/RocketLaunch';
import GppMaybe from '@mui/icons-material/GppMaybe';
import Clear from '@mui/icons-material/Clear';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const lastCheckInCount = 10;

const Start = ({ userLoggedIn, isAdmin, isSupervisor }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [checkinSuccess, setCheckinSuccess] = useState(location.state?.checkinSuccess || false);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [checkins, setCheckins] = useState([]);
  const handleSonsbergClick = () => {
    window.open('https://www.sv-sonsbeck.de/index.php/klimpansen/nutzung-kletterfels/oeffnungszeiten-und-kosten', '_blank');
  };

  const getCheckins = async () => {
    if (isAdmin || isSupervisor) {
      try {
        const response = await fetch(`${serverUrl}:${serverPort}/be/user/getCheckinsForToday`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          },
        });
        const data = await response.json();
        if (data && data.checkins && data.checkins.length >0) {
          setCheckins(data.checkins);
        }
      } catch (error) {
        console.error('Fehler beim Auslesen der Checkins:', error);
      }
    }
  };

  const deleteCheckin = async (checkinId) => {
    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/user/deleteCheckin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify({ checkinId }),
      });
      if (response.ok) {
        getCheckins();  // Aktualisieren Sie die Liste der Checkins nach erfolgreichem Löschen
      }
    } catch (error) {
      console.error('Fehler beim Löschen des Checkins:', error);
    }
  };
  

  useEffect(() => {
    getCheckins();

    const intervalId = setInterval(() => {
      getCheckins();
    }, 10000);  // 10 Sekunden

    return () => clearInterval(intervalId);
}, [userLoggedIn]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
};

  const getAgeColor = (age) => {
    if (age === "1") {
      return 'darkorange';
    } else if (age === "2") {
      return 'blue';
    }
    return 'black';
  };

  const getAgeGroupString = (age) => {
    if (age === "1") {
      return 'U14';
    } else if (age === "2") {
      return '14-18';
    }
    return 'Ü18';
  };

  const handleAllCheckinsClick = () => {
    navigate('/allCheckins');
  }

  const sortedCheckins = [...checkins].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const lastCheckins = sortedCheckins.slice(0, lastCheckInCount);

  return (
    <div className="start-container">
      <h1>Willkommen zur Sonsberg App</h1>
      {checkinSuccess === true && (
        <div className='checkin-success-div'>
          <p style={{textAlign:'right', height:'1px', cursor:'pointer'}} onClick={() => setCheckinSuccess(false)}><Clear /></p>
          <h2 className='checkin-success'>Erfolgreich eingecheckt!</h2>
          <p>Viel Spaß beim Klettern! Bitte beachte den Partnercheck vor jeder Route.</p>
          <p><img src='/img/partnercheck.png' width={250} /></p>
          </div>
      ) }
              {userLoggedIn && isSupervisor && lastCheckins.length > 0 && (
      <div className="admin-container">
        <h2 style={{textAlign:'center'}}>Die letzten {lastCheckins.length} Check-In's:</h2>
        <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableHead>
              <TableRow style={{backgroundColor:'lightgrey'}}>
                <TableCell className="admin-table-cell-left" style={{fontWeight:'bold'}}>Name</TableCell>
                <TableCell className="admin-table-cell" style={{fontWeight:'bold'}}>Uhrzeit</TableCell>
                <TableCell className="admin-table-cell" style={{fontWeight:'bold'}}>Alter</TableCell>
                <TableCell className="admin-table-cell" style={{fontWeight:'bold'}}>Mitglied</TableCell>
                <TableCell className="admin-table-cell" style={{fontWeight:'bold'}}>#</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lastCheckins.map((checkin) => (
                <TableRow key={checkin._id}>
                  <TableCell className="admin-table-cell-left">{checkin.firstName} {checkin.lastName}</TableCell>
                  <TableCell className="admin-table-cell">{formatDate(checkin.createdAt)}</TableCell>
                  <TableCell className="admin-table-cell" style={{ color: getAgeColor(checkin.age) }}>{getAgeGroupString(checkin.age)}</TableCell>
                  <TableCell className="admin-table-cell" style={{ color: checkin.membership ? 'green' : 'red' }}>{checkin.membership ? 'Ja' : 'Nein'}</TableCell>
                  <TableCell className="admin-table-cell">
                    <Button onClick={() => deleteCheckin(checkin._id)} type="submit" variant="contained" color="primary" className="admin-button"><Clear /></Button>
                  </TableCell>
                </TableRow>
              ))}
              {userLoggedIn && isSupervisor && sortedCheckins.length > lastCheckInCount && (
                <TableRow>
                  <TableCell colSpan={5} style={{textAlign:'center'}}><Button onClick={() => handleAllCheckinsClick()} type="submit" variant="contained" color="primary" className="checkin-button">Alle Checkins anzeigen</Button></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
     </div>
     )}

        {userLoggedIn && (isAdmin || isSupervisor) && (
        <h2>Check-In's heute gesamt: {checkins.length}</h2>
        )}

      <div className="tile-container">
        <Link to="/routes" className="tile">
        <img src="./img/toprope_icon.png" width="25" />&nbsp;<h2>Routen</h2>
        </Link>
        <Link to="/checkin" className="tile">
          <TaskAlt />&nbsp;<h2>Checkin</h2>
        </Link>
        {userLoggedIn === true && (
        <Link to="/achievements" className="tile">
          <EmojiEventsOutlined />&nbsp;<h2>Erfolge</h2>
        </Link>
        )}
        {userLoggedIn === true && (
        <Link to="/projects" className="tile">
          <RocketLaunch />&nbsp;<h2>Projekte</h2>
        </Link>
        )}

        {userLoggedIn !== true && (
        <Link to="/login" className="tile">
          <Login />&nbsp;<h2>Login</h2>
        </Link>
        )}
        <Link onClick={handleSonsbergClick} to="" className="tile">
          <LinkIcon />&nbsp;<h2>Sonsberg</h2>
        </Link>

        {userLoggedIn && isAdmin && (
        <Link to="/admin" className="tile">
          <GppMaybe />&nbsp;<h2>Admin</h2>
        </Link>
        )}
      </div>
    </div>
  );
};

export default Start;
