import React from 'react';
import { Link } from 'react-router-dom';
import './css/sonsberg.css';

const AdminScreen = () => {

  return (
    <div className="start-container">
      <h1>Admin-Bereich</h1>
      <div className="tile-container">
        <Link to="/reports" className="tile">
          <h2>Reports</h2>
        </Link>
        <Link to="/adminUser" className="tile">
          <h2>Admin User</h2>
        </Link>
        <Link to="/adminRoutes" className="tile">
          <h2>Routen Admin</h2>
        </Link>
        <Link to="/adminNewsletter" className="tile">
          <h2>Newsletter</h2>
        </Link>
      </div>
    </div>
  );
};

export default AdminScreen;
