import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

/*const theme = createTheme({
  typography: {
    fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: 'normal',
  },
  palette: {
    primary: {
      main: '#D10A11', // Setzen Sie hier die gewünschte Primärfarbe
    },
  },
}); */

const theme = createTheme({
  palette: {
    primary: {
      main: red[900],
    },
  },
});

export default theme;
