import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

function UserNewsletterScreen() {
    const [newsletterData, setNewsletterData] = useState(null);
    const [newsletterEnabled, setNewsletterEnabled] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    fetch(`${serverUrl}:${serverPort}/be/user/getNewsletterByToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        const { newsletterData, user } = data;
        setUser(user);
        if (newsletterData && newsletterData.newsletterEmail) {
            setNewsletterData(newsletterData);
            setNewsletterEnabled(true);
        }
      } else {
        console.log("No data.");
      }
      setLoading(false);
    })
    .catch(error => {
      console.error('Fehler beim Auslesen der Userdaten:', error);
    });
  };
  

  const handleNewsletterToggle = () => {

    if (!newsletterEnabled) {
        fetch(`${serverUrl}:${serverPort}/be/user/insertNewsletter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
            body: JSON.stringify({
                newsletterEmail: user.email,
            })
          })
            .then(response => {
                setNewsletterEnabled(true);
            })
            .catch(error => {
              console.error('Fehler beim Abbonieren des Newsletters:', error);
            });
    } else {
        fetch(`${serverUrl}:${serverPort}/be/user/deleteNewsletter/${newsletterData._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
          })
          .then(response => {
            setNewsletterEnabled(false);
          })
            .catch(error => {
              console.error('Fehler beim Löschen des Newsletters:', error);
            });
    }
    
  };

  return (
    <div className="checkin-container">
      <h1>Newsletter</h1>
      <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableBody>
            <TableRow>
                <TableCell className="checkin-table-cell">Email:</TableCell>
                <TableCell className="checkin-table-cell">{loading ? '' : user.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="checkin-table-cell">Newsletter erhalten:</TableCell>
                <TableCell className="checkin-table-cell"><FormControlLabel
        control={<Switch checked={newsletterEnabled} onChange={handleNewsletterToggle} />}
        label=""
      /></TableCell>
              </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
    </div>
  );
}

export default UserNewsletterScreen;
