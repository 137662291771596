import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import config from './config.js';
import './css/sonsberg.css';
import { TextField, Button, TableContainer, Table, TableRow, TableBody, TableCell, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

const ResetPasswordScreen = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [resetOK, setResetOK] = useState(false);
  const { resetToken } = useParams();
  const navigate = useNavigate();

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword && newPassword.length > 5 && newPassword === confirmPassword) {
      try {
        const response = await fetch(`${serverUrl}:${serverPort}/be/user/resetPassword`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            resetToken: resetToken,
            newPassword: newPassword,
          }),
        });

        const data = await response.json();
        if (data.success) {
          setResetOK(true);
        } else {
          setMessage(
            'Fehler beim Zurücksetzen des Passworts. Bitte stellen Sie sicher, dass der Link korrekt ist und er nicht abgelaufen ist.'
          );
        }
      } catch (error) {
        console.error('Fehler beim Zurücksetzen des Passworts:', error);
        setMessage(
          'Fehler beim Zurücksetzen des Passworts. Bitte stellen Sie sicher, dass der Link korrekt ist und er nicht abgelaufen ist.'
        );
      }
    } else {
      setMessage('Das neue Passwort muss mindestens 6 Zeichen lang sein und mit der Bestätigung übereinstimmen.');
    }
  };

  return (
    <div className="checkin-container">
      <h1>Passwort zurücksetzen</h1>
      <form onSubmit={handleSubmit}>
        <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableBody>
                <TableRow>
                    <TableCell className="checkin-table-cell">Neues Passwort</TableCell>
                <TableCell className="checkin-table-cell">
                    <TextField type="password" label="Neues Passwort" value={newPassword} onChange={handleNewPasswordChange} />
                </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="checkin-table-cell">Passwort bestätigen</TableCell>
                <TableCell className="checkin-table-cell">
                    <TextField
                    type="password"
                    label="Passwort bestätigen"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    />
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colSpan={2} className="checkin-table-cell" style={{textAlign:'center'}}>
                    <Button type="submit" variant="contained" color="primary" className="details-button">
                    Passwort zurücksetzen
                    </Button>
                </TableCell>
                </TableRow>
                {message && (
                <TableRow>
                    <TableCell colSpan={2} className="checkin-table-cell" style={{textAlign:'center', color:'red'}}>
                    <p>{message}</p>
                    </TableCell>
                </TableRow>
                )}
                {resetOK && (
                <TableRow>
                    <TableCell colSpan={2} className="checkin-table-cell" style={{textAlign:'center'}}>
                    <p>Passwort erfolgreich zurückgesetzt. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.</p>
                    <Link to="/login">Hier geht's zum Login</Link>
                    </TableCell>
                </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
    </div>
  );
};

export default ResetPasswordScreen;
