import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, TextareaAutosize } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const difficultyOrder = config.difficultyOrder;
const colorMapping = config.colorMapping;

const AdminCreateRouteScreen = () => {
  const [route, setRoute] = useState({});
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const createRoute = () => {
    const data = {
      routenumber: route.routenumber,
      routename: route.routename,
      color: route.color || "schwarz",
      difficulty: route.difficulty || "3-",
      description: route.description
    };
  
    fetch(`${serverUrl}:${serverPort}/be/routes/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
        if (data._id !== undefined) {
            navigate(`/routeDetailsAdmin/${data._id}`);
        } else {
            setError('Fehler beim Anlegen der Route. Bitte versuche es erneut.');
        }
    })
    .catch(error => {
        setError('Fehler beim Anlegen der Route: ' + error);
        console.error('Fehler beim Aktualisieren der Route:', error);
    });    
  };  
  
  const handleChange = (field, value) => {
    setRoute({ ...route, [field]: value });
  };

  const difficultyOptions = Object.keys(difficultyOrder)
  .sort((a, b) => difficultyOrder[a] - difficultyOrder[b])
  .map((difficulty, index) => (
    <MenuItem key={index} value={difficulty}>{difficulty}</MenuItem>
  ));

  const colorOptions = Object.keys(colorMapping).map((color, index) => (
    <MenuItem key={index} value={color}>{color}</MenuItem>
  ));

  return (
    <div className="centered-container" style={{textAlign:'center'}}>
      <h1>Neue Route anlegen</h1>
      {error && <div className="error-message">{error}</div>}
      <TableContainer component={Paper} className="details-table-container">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="details-cell">Routennummer:</TableCell>
              <TableCell className="details-cell"><input type="text" onChange={(e) => handleChange('routenumber', e.target.value)} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Name der Route:</TableCell>
              <TableCell className="details-cell">
                <input type="text" onChange={(e) => handleChange('routename', e.target.value)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Beschreibung:</TableCell>
              <TableCell className="details-cell">
                <TextareaAutosize
                  minRows={3}
                  rows={5}
                  onChange={(e) => handleChange('description', e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">UIAA-Grad:</TableCell>
              <TableCell className="details-cell">
                <FormControl>
                  <InputLabel id="difficulty-label">Schwierigkeitsgrad</InputLabel>
                  <Select
                    labelId="difficulty-label"
                    value={route.difficulty || "3-"}
                    onChange={(e) => handleChange('difficulty', e.target.value)}
                    >
                    {difficultyOptions}
                    </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Farbe der Griffe:</TableCell>
              <TableCell className="details-cell">
                <FormControl>
                  <InputLabel id="color-label">Farbe</InputLabel>
                  <Select
                    labelId="color-label"
                    value={route.color || "schwarz"}
                    onChange={(e) => handleChange('color', e.target.value)}
                    >
                    {colorOptions}
                    </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" onClick={createRoute}>
  Route anlegen
</Button>
    </div>
  );
};

export default AdminCreateRouteScreen;
