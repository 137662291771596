import React, { useEffect, useState, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import config from './config.js';
import { useNavigate } from 'react-router-dom';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const difficultyOrder = config.difficultyOrder;
const colorMapping = config.colorMapping;
const colorCircleStyle = config.colorCircleStyle;
const zebraCircleContainerStyle = config.zebraCircleContainerStyle;
const zebraCircleStyle1 = config.zebraCircleStyle1;
const zebraCircleStyle2 = config.zebraCircleStyle2;
const Scale_French = config.difficultyScaleFrench;
const Scale_YDS = config.difficultyScaleYDS;
const Scale_GB = config.difficultyScaleGB;
const difficultyMappingFrench = config.difficultyMappingFrench;
const difficultyMappingYDS = config.difficultyMappingYDS;
const difficultyMappingGB = config.difficultyMappingGB;

const ProjectsPage = ({ difficultyScale }) => {
  const [routes, setRoutes] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [projectSortOrder, setProjectSortOrder] = useState({});
  const [topropeSortOrder, setTopropeSortOrder] = useState({});
  const [vorstiegSortOrder, setVorstiegSortOrder] = useState({});
  const [projects, setProjects] = useState([]);
  const [topropeRoutes, setTopropeRoutes] = useState([]);
  const [vorstiegRoutes, setVorstiegRoutes] = useState([]);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const getTopropeRoutes = useCallback(() => {
    const topropeRoutes = routes.filter(route => {
      const mapping = mappings.find(mapping => mapping.route_id === route._id)
      return mapping && mapping.toprope;
    });
    return topropeRoutes;
  }, [routes, mappings]);

  const getVorstiegRoutes = useCallback(() => {
    const vorstiegRoutes = routes.filter(route => {
      const mapping = mappings.find(mapping => mapping.route_id === route._id)
      return mapping && mapping.vorstieg;
    });
    return vorstiegRoutes;
  }, [routes, mappings]);

  const getProjects = useCallback(() => {
    const projects = routes.filter(route => {
      const mapping = mappings.find(mapping => mapping.route_id === route._id)
      return mapping && mapping.project;
    });
    return projects;
  }, [routes, mappings]);

  useEffect(() => {
    // Fetch all routes
    fetch(`${serverUrl}:${serverPort}/be/routes/getall`)
      .then(response => response.json())
      .then(data => {
        setRoutes(data);
      })
      .catch(error => console.error('Error fetching routes:', error));

    // Fetch user mappings
    fetch(`${serverUrl}:${serverPort}/be/routes/getMappingForUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
    })
      .then(response => response.json())
      .then(data => {
        setMappings(data);
      })
      .catch(error => console.error('Error fetching user mappings:', error));
  }, [token, difficultyScale]);

  useEffect(() => {
    setProjects(getProjects());
    setTopropeRoutes(getTopropeRoutes());
    setVorstiegRoutes(getVorstiegRoutes());
  }, [getProjects, getTopropeRoutes, getVorstiegRoutes]);

  const handleProjectSort = column => {
    const sortedProjects = [...projects];

    if (column === 'routenumber') {
      sortedProjects.sort((a, b) => {
        const valueA = Number(a[column])
        const valueB = Number(b[column])
        return valueA - valueB;

      });
    } else {
      sortedProjects.sort((a, b) => {
        const valueA = String(a[column]).toLowerCase()
        const valueB = String(b[column]).toLowerCase()
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
      });
    }

    if (column === 'difficulty') {
      sortedProjects.sort((a, b) => compareDifficulty(a[column], b[column]))
    }

    if (topropeSortOrder[column] === 'desc') {
      sortedProjects.reverse();
      setProjectSortOrder({ ...projectSortOrder, [column]: 'asc' })
    } else {
      setProjectSortOrder({ ...projectSortOrder, [column]: 'desc' })
    }

    setProjects(sortedProjects);
  };

  const handleClick = routeId => {
    navigate(`/routeDetails/${routeId}`);
  };

  const handleTopropeSort = column => {
    const sortedTopropeRoutes = [...topropeRoutes];

    if (column === 'routenumber') {
      sortedTopropeRoutes.sort((a, b) => {
        const valueA = Number(a[column])
        const valueB = Number(b[column])
        return valueA - valueB;

      });
    } else {
      sortedTopropeRoutes.sort((a, b) => {
        const valueA = String(a[column]).toLowerCase()
        const valueB = String(b[column]).toLowerCase()
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
      });
    }

    if (column === 'difficulty') {
      sortedTopropeRoutes.sort((a, b) => compareDifficulty(a[column], b[column]))
    }

    if (topropeSortOrder[column] === 'desc') {
      sortedTopropeRoutes.reverse();
      setTopropeSortOrder({ ...topropeSortOrder, [column]: 'asc' })
    } else {
      setTopropeSortOrder({ ...topropeSortOrder, [column]: 'desc' })
    }

    setTopropeRoutes(sortedTopropeRoutes);
  };

  const handleVorstiegSort = column => {
    const sortedVorstiegRoutes = [...vorstiegRoutes];

    if (column === 'routenumber') {
      sortedVorstiegRoutes.sort((a, b) => {
        const valueA = Number(a[column])
        const valueB = Number(b[column])
        return valueA - valueB;

      });
    } else {
      sortedVorstiegRoutes.sort((a, b) => {
        const valueA = String(a[column]).toLowerCase()
        const valueB = String(b[column]).toLowerCase()
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
      });
    }

    if (column === 'difficulty') {
      sortedVorstiegRoutes.sort((a, b) => compareDifficulty(a[column], b[column]))
    }

    if (vorstiegSortOrder[column] === 'desc') {
      sortedVorstiegRoutes.reverse();
      setVorstiegSortOrder({ ...vorstiegSortOrder, [column]: 'asc' })
    } else {
      setVorstiegSortOrder({ ...vorstiegSortOrder, [column]: 'desc' })
    }

    setVorstiegRoutes(sortedVorstiegRoutes);
  };

  const compareDifficulty = (a, b) => {
    return difficultyOrder[a] - difficultyOrder[b];
  };

  const getDifficultyLabel = (routeDifficulty) => {
    if (difficultyScale === Scale_French) {
      return difficultyMappingFrench[routeDifficulty];
    }
    if (difficultyScale === Scale_YDS) {
      return difficultyMappingYDS[routeDifficulty];
    }
    if (difficultyScale === Scale_GB) {
      return difficultyMappingGB[routeDifficulty];
    }
    return routeDifficulty;
  };

  return (
    <div>
      <h1 style={{ color: '#D10A11', fontSize: '2.5rem', textAlign: 'center' }}>Meine Projekte</h1>
      <h2 style={{ color: '#D10A11', fontSize: '1.8rem', marginBottom: '1rem' }}>Aktuelle Projekte{projects.length>0 ? ` (${projects.length})` : ''}</h2>
      {projects.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="projects-table">
            <TableHead>
              <TableRow style={{ backgroundColor: '#DBDCD7' }}>
                <TableCell className="details-cell" onClick={() => handleProjectSort('routename')} style={{ cursor: 'pointer' }}>
                  Name
                </TableCell>
                <TableCell className="details-cell" onClick={() => handleProjectSort('color')} style={{ cursor: 'pointer' }}>
                  Farbe
                </TableCell>
                <TableCell className="details-cell" onClick={() => handleProjectSort('difficulty')} style={{ cursor: 'pointer' }}>
                {difficultyScale}-Grad
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map(route => (
                <TableRow key={route._id}>
                  <TableCell onClick={() => handleClick(route._id)} className="details-cell"><BookmarkIcon style={{color: '#D10A11'}} />{route.routename}</TableCell>
                  <TableCell onClick={() => handleClick(route._id)} className="details-cell">{route.color === 'Zebra' ? (
                      <div style={zebraCircleContainerStyle}>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'black',
                            ...zebraCircleStyle1, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                        <div
                          style={{
                            ...colorCircleStyle,
                            backgroundColor: 'white',
                            ...zebraCircleStyle2, // Zebra-Kreis-Stil hier anwenden
                          }}
                        ></div>
                      </div>
                    ) : route.color === 'Riss / Struktur' ? (
                      <div>Riss</div>
                    ) : (
                      // Standard-Kreis für andere Farben
                      <div
                        style={{
                          ...colorCircleStyle,
                          backgroundColor: colorMapping[route.color],
                        }}
                      ></div>
                    )}</TableCell>
                  <TableCell onClick={() => handleClick(route._id)} className="details-cell">{getDifficultyLabel(route.difficulty)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p style={{ textAlign: 'center', fontStyle: 'italic' }}>Derzeit keine Projekte.</p>
      )}
    </div>
  );
};

export default ProjectsPage;
