import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FitlerList from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Logout from '@mui/icons-material/Logout';
import Security from '@mui/icons-material/Security';
import Person from '@mui/icons-material/Person';
import Policy from '@mui/icons-material/Policy';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import './css/sonsberg.css'; 

export default function NavBar({ loggedIn, onLogout, onDeleteFilter }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const isRoutesTablePage = location.pathname === '/routes';
  const isStartPage = location.pathname === '/';
  const storedFilterOptions = localStorage.getItem('filterOptions');
  const navigate = useNavigate();

  useEffect(() => {
    setAnchorEl(null);
  }, [loggedIn]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    onLogout(); // Call the provided logout function
  };

  const handleDeleteFilter = () => {
    localStorage.setItem('filterOptions', '');
    onDeleteFilter();
  };

  const handleDatenschutzClick = () => {
    window.open('https://www.sv-sonsbeck.de/sv-sonsbeck/unser-verein/datenschutzerklaerung', '_blank');
    handleMenuClose();
  };

  const handleSettingsClick = () => {
    navigate("/profile");
    handleMenuClose();
  };

  const handleImpressumClick = () => {
    window.open('https://www.sv-sonsbeck.de/index.php/sv-sonsbeck/unser-verein/impressum', '_blank');
    handleMenuClose();
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="navbar">
        <Toolbar className="toolbar">
          {!isStartPage && (
        <IconButton
            edge="start"
            color="inherit"
            aria-label="go-back"
            onClick={handleGoBack}
          >
            <ArrowBackIcon />
          </IconButton>
          )}
            <Button
              component={Link}
              to="/"
              color="inherit"
              className="app-title"
              startIcon={<img src="/img/sv_sonsbeck_logo.png" alt="" className="logo" />}
            >
              SonsBerg
            </Button>
            <div style={{ flexGrow: 1 }} />
          {loggedIn && isRoutesTablePage && (
            <Typography variant="h6" component="div">
              <Button
                component={Link}
                to="/filter" // Link to the FilterScreen component
                color="inherit"
                className="filter-button"
              >
                <FitlerList />
              </Button>
            </Typography>
            
          )}
          {loggedIn && (
            <div>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleMenuOpen} // Open the menu on click
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem component={Link} to="/" onClick={handleMenuClose}>
                <HomeOutlined />&nbsp; Startseite
                </MenuItem>
                <MenuItem onClick={handleSettingsClick}>
                <Person />&nbsp; Mein Profil
                </MenuItem>
                <MenuItem onClick={handleImpressumClick}>
                <Policy />&nbsp; Impressum
                </MenuItem>
                <MenuItem onClick={handleDatenschutzClick}>
                <Security />&nbsp; Datenschutz
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                <Logout />&nbsp; Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
