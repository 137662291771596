import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, TextareaAutosize } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import config from './config.js';
import './css/sonsberg.css';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const difficultyOrder = config.difficultyOrder;
const colorMapping = config.colorMapping;

const RouteDetailsAdminScreen = () => {
  const { routeId } = useParams();
  const [route, setRoute] = useState(null);
  const [message, setMessage] = useState(null);
  const [reload, setReload] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${serverUrl}:${serverPort}/be/routes/getDetails/${routeId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
      body: JSON.stringify({
        routeId: routeId,
      }),
    })
      .then(response => response.json())
      .then(data => setRoute(data))
      .catch(error => console.error('Fehler beim Abrufen der Routen:', error));

  }, [routeId, token, reload]);

  if (!route) {
    return <p>Lade Routen-Details...</p>;
  }

  const saveRouteDetails = () => {  
    fetch(`${serverUrl}:${serverPort}/be/routes/update/${routeId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      },
      body: JSON.stringify({
        routenumber: route.routenumber, 
        ropenumber: route.ropenumber, 
        routename: route.routename, 
        color: route.color, 
        difficulty: route.difficulty, 
        description: route.description, 
        icon: route.icon, 
        picture: route.picture,
      }),
    })
    .then(response => response.json())
    .then(data => {
        setMessage("Route erfolgreich gespeichert.");
        setReload(true);
    })
    .then(() => setReload(false))
    .catch(error => console.error('Fehler beim Aktualisieren der Route:', error));
  };
  
  const deleteRoute = () => {
    const confirmDelete = window.confirm('Möchten Sie diese Route wirklich löschen?');
    
    if (confirmDelete) {
      fetch(`${serverUrl}:${serverPort}/be/routes/delete/${routeId}`, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Ungültige Antwort vom Server');
          } else {
            navigate(`/adminRoutes`);
            setMessage("Route erfolgreich gelöscht.");
          }
          return response.json();
        })
        .catch(error => console.error('Fehler beim Löschen der Route:', error));
    }
  };
  
  
  const handleChange = (field, value) => {
    setRoute({ ...route, [field]: value });
  };

  const difficultyOptions = Object.keys(difficultyOrder)
  .sort((a, b) => difficultyOrder[a] - difficultyOrder[b])
  .map((difficulty, index) => (
    <MenuItem key={index} value={difficulty}>{difficulty}</MenuItem>
  ));

  const colorOptions = Object.keys(colorMapping).map((color, index) => (
    <MenuItem key={index} value={color}>{color}</MenuItem>
  ));

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append('pictureFile', file);
    }
  
    fetch(`${serverUrl}:${serverPort}/be/routes/uploadImage/${routeId}`, {
      method: 'POST',
      headers: {
        'Authorization': `${token}`
      },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
        setReload(true);
    })
    .then(() => setReload(false))
    .catch(error => console.error('Fehler beim Aktualisieren der Route:', error));
  };

  return (
    <div className="centered-container" style={{textAlign:'center'}}>
      <h1>Route Details</h1>
      {message && <p className="save-message">{message}</p>}
      <TableContainer component={Paper} className="details-table-container">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="details-cell">Routennummer:</TableCell>
              <TableCell className="details-cell">{route.routenumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Name der Route:</TableCell>
              <TableCell className="details-cell">
                <input type="text" value={route.routename} onChange={(e) => handleChange('routename', e.target.value)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Beschreibung:</TableCell>
              <TableCell className="details-cell">
                <TextareaAutosize
                  minRows={3}
                  rows={5}
                  value={route.description}
                  onChange={(e) => handleChange('description', e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">UIAA-Grad:</TableCell>
              <TableCell className="details-cell">
                <FormControl>
                  <InputLabel id="difficulty-label">UIAA</InputLabel>
                  <Select
                    labelId="difficulty-label"
                    value={route.difficulty}
                    onChange={(e) => handleChange('difficulty', e.target.value)}
                  >
                    {difficultyOptions}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Farbe der Griffe:</TableCell>
              <TableCell className="details-cell">
                <FormControl>
                  <InputLabel id="color-label">Farbe</InputLabel>
                  <Select
                    labelId="color-label"
                    value={route.color}
                    onChange={(e) => handleChange('color', e.target.value)}
                  >
                    {colorOptions}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} className="details-cell" style={{ textAlign:'center'}}><img src={`/img/routes/${route.picture}`} width="300" alt={`${route.routename}`}  /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="details-cell">Neues Bild hochladen</TableCell>
              <TableCell className="details-cell">
                    <input type="file" onChange={handleImageUpload} />
                </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" onClick={saveRouteDetails}>Speichern</Button>&nbsp;
      <Button variant="contained" color="secondary" onClick={deleteRoute}>Route löschen</Button>

    </div>
  );
};

export default RouteDetailsAdminScreen;
