import React, { useState, useEffect, useRef } from 'react';
import config from './config.js';
import './css/sonsberg.css';
import { TableContainer, Table, TableRow, TableCell, TableBody, Paper, TextField, Button } from '@mui/material';
import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded';
import SyncProblemOutlined from '@mui/icons-material/SyncProblemOutlined';
import ChangeCircleOutlined from '@mui/icons-material/ChangeCircleOutlined';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;

const ChangePasswordScreen = () => {
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState('');
    const [username, setUsername] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [errors, setErrors] = useState({
      oldPassword: false,
      newPassword1: false,
      newPassword2: false,
    });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const token = localStorage.getItem('token');
    const oldPasswordRef = useRef(null);
    const newPassword1Ref = useRef(null);
    const newPassword2Ref = useRef(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await fetch(`${serverUrl}:${serverPort}/be/user/getUserByToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          },
        });
        if (response.ok) {
            const data = await response.json();
            const user = data.user;
            setUserId(user._id);
            setUsername(user.username);
            setLoading(false);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        setLoading(false);
      }
    };
    fetchData();

    document.getElementById("oldPasswordInput")?.focus();
  }, []);

  const handlePasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword1(e.target.value);
  };

  const handleNewPassword2Change = (e) => {
    setNewPassword2(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (e.target === oldPasswordRef.current) {
        newPassword1Ref.current.focus();
      } else if (e.target === newPassword1Ref.current) {
        newPassword2Ref.current.focus();
      } else if (e.target === newPassword2Ref.current) {
        handleSubmit(e);
      }
    }
  };

  const validateFields = () => {
    const newErrors = {
      oldPassword: false,
      newPassword1: false,
      newPassword2: false,
    };
  
    if (!oldPassword || oldPassword.length <= 1) {
      newErrors.oldPassword = true;
    }
  
    if (!newPassword1 || newPassword1.length <= 5) {
      newErrors.newPassword1 = true;
    }
  
    if (!newPassword2 || newPassword2 !== newPassword1) {
      newErrors.newPassword2 = true;
    }
  
    setErrors(newErrors);
  
    return !Object.values(newErrors).some((error) => error);
  };
  

  const handleSubmit = async (e) => {
    setMessage("");
    setSuccess(false);

    e.preventDefault();

    if (validateFields()) {
      try {
        const response = await fetch(`${serverUrl}:${serverPort}/be/user/updateUser/${userId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
        },
          body: JSON.stringify({
            passwordOld: oldPassword,
            passwordNew: newPassword1,
          }),
        });
        const data = await response.json();
        if (!data.success) {
          setMessage('Fehler beim Speichern der Änderungen.');
        } else {
          setMessage('Password erfolgreich geändert');
          setSuccess(true);
          setOldPassword("");
          setNewPassword1("");
          setNewPassword2("");
          document.getElementById("oldPasswordInput")?.focus();
        }
      } catch (error) {
        console.error('Fehler beim Speichern der Änderungen:', error);
        setMessage('Fehler beim Speichern der Änderungen.');
      }
    } else {
      setMessage('Fehler beim Speichern der Änderungen.');
    }
  };

  return (
    <div className="checkin-container">
      <h1>Passwort ändern</h1><br />
      <p>Auf dieser Seite kannst du dein Password ändern.<br />
      Du änderst das Password für den User: <b>{username}</b></p><br /><br />
      {loading ? (
        <div>Lade Daten...</div>
      ) : (
      <form onSubmit={handleSubmit}>
        <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="checkin-table-cell">Aktuelles Passwort:</TableCell>
                <TableCell className="checkin-table-cell">
                <TextField
                  type="password"
                  id="oldPasswordInput"
                  label="Aktuelles Passwort"
                  value={oldPassword}
                  onChange={handlePasswordChange}
                  error={errors.oldPassword}
                  helperText={errors.oldPassword && "Aktuelles Passwort erforderlich"}
                  inputRef={oldPasswordRef}
                  onKeyPress={handleKeyPress}
                />  
                </TableCell>
              </TableRow>
              <TableRow>
               <TableCell className="checkin-table-cell">Neues Passwort:</TableCell>
                <TableCell className="checkin-table-cell">
                <TextField
                  type="password"
                  label="Neues Passwort"
                  value={newPassword1}
                  onChange={handleNewPasswordChange}
                  error={errors.newPassword1}
                  helperText={errors.newPassword1 && "Mindestens 6 Zeichen erforderlich"}
                  inputRef={newPassword1Ref}
                  onKeyPress={handleKeyPress}
                />
                </TableCell>
              </TableRow>
              <TableRow>
              <TableCell className="checkin-table-cell">Passwort wiederholen:</TableCell>
                <TableCell className="checkin-table-cell">
                <TextField
                  type="password"
                  label="Passwort wiederholen"
                  value={newPassword2}
                  onChange={handleNewPassword2Change}
                  error={errors.newPassword2}
                  helperText={errors.newPassword2 && "Passwörter stimmen nicht überein"}
                  inputRef={newPassword2Ref}
                  onKeyPress={handleKeyPress}
                />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className="checkin-table-cell" style={{textAlign:'center'}}>
                  <Button type="submit" variant="contained" color="primary" className="details-button">
                    <ChangeCircleOutlined />&nbsp;Passwort ändern
                  </Button>
                </TableCell>
              </TableRow>
              {message && (<TableRow>
                <TableCell colSpan={2} className="checkin-table-cell" style={{textAlign:'center', color: success?'green':'#D10A11'}}>
                    <p>{success && (
                    <CheckCircleOutlineRounded />
                  )}{!success && (
                    <SyncProblemOutlined />
                  )}&nbsp;{message}</p>
                </TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
      )}
    </div>
  );
};

export default ChangePasswordScreen;
