import React from 'react';
import { Link } from 'react-router-dom';
import './css/sonsberg.css';

const RegistrationSuccessScreen = () => {
  return (
    <div className="registration-success-container">
      <h2>Registrierung erfolgreich</h2>
      <p>Herzlichen Glückwunsch! Deine Registrierung war erfolgreich.</p>
      <p>Du kannst dich jetzt <Link to="/login">hier</Link> anmelden.</p>
    </div>
  );
};

export default RegistrationSuccessScreen;
