import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button } from '@mui/material';
import config from './config.js';
import './css/sonsberg.css';
import Clear from '@mui/icons-material/Clear';

const serverUrl = config.serverUrl;
const serverPort = config.serverPort;
const lastCheckInCount = 10;

const AllCheckins = ({ userLoggedIn, isAdmin, isSupervisor }) => {
  const location = useLocation();
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [checkins, setCheckins] = useState([]);

  const getCheckins = async () => {
    if (isSupervisor) {
      try {
        const response = await fetch(`${serverUrl}:${serverPort}/be/user/getCheckinsForToday`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          },
        });
        const data = await response.json();
        if (data && data.checkins && data.checkins.length >0) {
          setCheckins(data.checkins);
        }
      } catch (error) {
        console.error('Fehler beim Auslesen der Checkins:', error);
      }
    }
  };

  const deleteCheckin = async (checkinId) => {
    try {
      const response = await fetch(`${serverUrl}:${serverPort}/be/user/deleteCheckin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        },
        body: JSON.stringify({ checkinId }),
      });
      if (response.ok) {
        getCheckins();  // Aktualisieren Sie die Liste der Checkins nach erfolgreichem Löschen
      }
    } catch (error) {
      console.error('Fehler beim Löschen des Checkins:', error);
    }
  };
  

  useEffect(() => {
    getCheckins();

    const intervalId = setInterval(() => {
      getCheckins();
    }, 10000);  // 10 Sekunden

    return () => clearInterval(intervalId);
}, [userLoggedIn]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
};


  const getAgeColor = (age) => {
    if (age === 1) {
      return 'darkorange';
    } else if (age === 2) {
      return 'blue';
    }
    return 'black';
  };

  const getAgeGroupString = (age) => {
    if (age === 1) {
      return 'U14';
    } else if (age === 2) {
      return '14-18';
    }
    return 'Ü18';
  };

  const sortedCheckins = [...checkins].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const lastCheckins = sortedCheckins;

  return (
    <div className="start-container">
      <h1>Alle Checkins heute</h1>
      {userLoggedIn && isSupervisor && lastCheckins.length > 0 && (
      <div className="admin-container">
        <h2>Check-In's heute gesamt: {checkins.length}</h2>
        <TableContainer component={Paper} className="checkin-table">
          <Table>
            <TableHead>
              <TableRow style={{backgroundColor:'lightgrey'}}>
                <TableCell className="admin-table-cell-left" style={{fontWeight:'bold'}}>Name</TableCell>
                <TableCell className="admin-table-cell" style={{fontWeight:'bold'}}>Uhrzeit</TableCell>
                <TableCell className="admin-table-cell" style={{fontWeight:'bold'}}>Alter</TableCell>
                <TableCell className="admin-table-cell" style={{fontWeight:'bold'}}>Mitglied</TableCell>
                <TableCell className="admin-table-cell" style={{fontWeight:'bold'}}>#</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lastCheckins.map((checkin) => (
                <TableRow key={checkin._id}>
                  <TableCell className="admin-table-cell-left">{checkin.firstName} {checkin.lastName}</TableCell>
                  <TableCell className="admin-table-cell">{formatDate(checkin.createdAt)}</TableCell>
                  <TableCell className="admin-table-cell" style={{ color: getAgeColor(checkin.age) }}>{getAgeGroupString(checkin.age)}</TableCell>
                  <TableCell className="admin-table-cell" style={{ color: checkin.membership ? 'green' : 'red' }}>{checkin.membership ? 'Ja' : 'Nein'}</TableCell>
                  <TableCell className="admin-table-cell">
                    <Button onClick={() => deleteCheckin(checkin._id)} type="submit" variant="contained" color="primary" className="admin-button"><Clear /></Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
     </div>
     )}

    </div>
  );
};

export default AllCheckins;
